"use client";
import { Box } from "@chakra-ui/react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  verticalListSortingStrategy,
  arrayMove,
  SortableContext,
} from "@dnd-kit/sortable";
import { SortableItem } from "../sortable-item/SortableItem";
import "./DragDropContainer.scss";
import { SortableElement } from "../sortable-element/SortableElement";

export default function DragDropContainer({
  items,
  setItems,
  displayIndex,
  isElement,
}: any) {
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((item: any) => {
        const activeIndex = item.indexOf(active.id);
        const overIndex = item.indexOf(over.id);
        return isElement
          ? arrayMove(item, active.id - 1, over.id - 1)
          : arrayMove(item, activeIndex, overIndex);
      });
    }
  };
  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items?.map((item: any, dropIndex: any) => (
          <Box key={`dropIndex${dropIndex}`} className="drag-drop-container">
            {displayIndex && (
              <div className="drag-drop-index">{dropIndex + 1}.</div>
            )}
            {!isElement ? (
              <SortableItem key={item} value={item} />
            ) : (
              <SortableElement value={item} />
            )}
          </Box>
        ))}
      </SortableContext>
    </DndContext>
  );
}
 