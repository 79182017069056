"use client";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "./CustomMarkdown.scss";
import { Table, Thead, Tr, Td, TableContainer } from "@chakra-ui/react";
import {
  Box,
  Image,
  HStack,
  UnorderedList,
  OrderedList,
  Text,
  Divider,
  VStack,
} from "@/components/ChakraUiManager";
import { QuickReference } from "public/assets";
import CodeContainer from "../code/CodeContainer";
import { Element } from "react-scroll";

/**
 * Component for "Markdown" Slices.
 */
const CustomMarkdown = ({ data }: any): JSX.Element => {
  const isSingleLine = (text: string) => {
    // Check if there are any newline characters in the text
    return !/\r\n|\r|\n/.test(text);
  };
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      components={{
        table(props) {
          const { node, ...rest } = props;
          return (
            <TableContainer whiteSpace="normal" className="md-table-container">
              <Table {...rest} variant="simple" className="markdown-table" />
            </TableContainer>
          );
        },
        td(props) {
          const { node, ...rest } = props;
          return <Td {...rest} />;
        },
        tr(props) {
          const { node, ...rest } = props;
          return <Tr {...rest} />;
        },
        thead(props) {
          const { node, ...rest } = props;
          return <Thead {...rest} />;
        },
        h2(props) {
          const { node, ...rest } = props;
          const title: any = node?.children[0];
          return (
            <Element
              name={`${title.value}`}
              className="slice-item"
              id={`${title.value.replaceAll(" ", "-")}`}
            >
              {title.value && (
                <Divider
                  orientation="horizontal"
                  className="markdown-divider"
                />
              )}
              <h2 {...rest} className="markdown-heading-two"></h2>
            </Element>
          );
        },
        h5(props) {
          const { node, ...rest } = props;
          const child: any = node?.children[0];

          if (child?.value.startsWith("Example")) {
            let className = "";
            switch (child?.value) {
              case "Example-bm":
                className = "example-bm";
                break;
              case "Example-tm":
                className = "example-tm";
                break;
              default:
                className = "example";
                break;
            }
            return (
              <HStack gap={4} className={className}>
                <Box width="24px" height="24px">
                  <Image src={QuickReference.src} alt="Campaign" />{" "}
                </Box>
                <h5 id={rest.children?.toString().replaceAll(" ", "-")}>
                  Example
                </h5>
              </HStack>
            );
          } else {
            return (
              <h5
                {...rest}
                id={rest.children?.toString().replaceAll(" ", "-")}
              ></h5>
            );
          }
        },
        a: (props) => {
          const { node, ...rest } = props;
          return <a className="markdown-link" {...rest} />;
        },
        ul: (props) => {
          const { node, ...rest } = props;
          return (
            <UnorderedList spacing={6.4} {...rest} className="markdown-list" />
          );
        },
        ol: (props) => {
          const { node, ...rest } = props;
          return <OrderedList {...rest} className="markdown-list" />;
        },
        p: (props) => {
          const { node, ...rest } = props;
          return (
            <Text mb={6.4} mt={0} {...rest} className="markdown-paragraph" />
          );
        },
        code: (props) => {
          const { node, ...rest } = props;
          const code: any = node?.children?.[0];
          const slice = {
            data: {
              code: code?.value,
            },
          };
          if (isSingleLine(code?.value)) {
            return (
              <span className="markdown-single-line-code">{code?.value}</span>
            );
          } else {
            return (
              <Box className="markdown-code">
                <CodeContainer slice={slice} withoutHeader={true} />
              </Box>
            );
          }
        },
        img: (props) => {
          const data: any = props.node?.properties;
          if (data.src.endsWith(".svg")) {
            return (
              <Image
                src={data.src}
                alt={data.alt || "Icon"}
                w={data.width || "24px"}
                className="inline-icon"
              />
            );
          }
          return (
            <VStack align="center">
              <Image
                className="markdown-image"
                src={data.src}
                alt={data.alt || "Screenshot image "}
              />
            </VStack>
          );
        },
      }}
    >
      {data}
    </ReactMarkdown>
  );
};

export default CustomMarkdown;
