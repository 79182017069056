"use client";

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SearchIcon,
  SmallCloseIcon,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  Wrap,
} from "@/components/ChakraUiManager";
import { AppModal, InputText } from "../..";
import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./ModalSelector.scss";
import { modalSelectorBtnProps } from "@/components/data/global-config";
import { IFX_ACCT_PRODUCT_TYPE, Option, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

export type ModalSelectorProp<T> = {
  value: T;
  onChange: (options: T) => void;
  linkName: string;
  modalTitle: string;
  optionsTitle?: string;
  optionsDescTitle?: string;
  optionList: Option<string | number>[];
  isSingleSelect?: boolean;
  isClearable?: boolean;
  showSelection?: "description" | "value" | "value - description";
  showDashedBorder?: boolean;
};

// search options functionality is TBD
export default function ModalSelector<T extends string | string[]>(
  props: ModalSelectorProp<T>
) {
  const {
    value,
    onChange,
    linkName,
    modalTitle,
    optionsTitle,
    optionsDescTitle,
    optionList,
    isSingleSelect = true,
    isClearable = true,
    showSelection = "value",
    showDashedBorder = true,
  } = props;
  const modalRef = useRef<any>(); //modal ref to open/close the modal
  const [filterText, setFilterText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(value);
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  // sync internal state when value changes
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  // Get option from given value
  const getOption = useCallback(
    (val: string) => {
      return optionList.find((opt) => opt.value === val);
    },
    [optionList]
  );

  const tagLabelValue = (val: string) => {
    const option = getOption(val);
    if (option) {
      switch (showSelection) {
        case "description":
          return option.label;
        case "value - description":
          return `${option.value} - ${option.label}`;
        case "value":
          return option.value;
        default:
          return option.value;
      }
    }
    return "";
  };

  function showModal() {
    setTimeout(() => {
      modalRef?.current?.openModal();
    });
  }

  const removeSelectedOption = (removedOption: string) => () => {
    if (!Array.isArray(value)) {
      onChange("" as T);
    } else {
      const updateValues = value.filter((val) => val !== removedOption);
      onChange(updateValues as T);
    }
  };

  // clear all
  const clearAll = () => {
    if (!Array.isArray(value)) {
      onChange("" as T);
    } else {
      onChange([] as string[] as T);
    }
  };

  // Save and close modal
  const onSave = () => {
    onChange(selectedOptions);
    modalRef?.current?.closeModal();
  };

  // reset internal selection state
  const onCancel = () => {
    setSelectedOptions(value);
  };

  const _handleSelect = (selectedValue: string) => (e: any) => {
    e.preventDefault();
    setSelectedOptions((oldValue) => {
      if (!Array.isArray(oldValue)) {
        return selectedValue as T;
      }
      if (oldValue.includes(selectedValue)) {
        return oldValue.filter((val) => val !== selectedValue) as T;
      }
      return [...oldValue, selectedValue] as T;
    });
  };

  const filteredOptions = useMemo(() => {
    return optionList.filter((option) => {
      return (
        option.label?.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        option.value
          .toString()
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) > -1
      );
    });
  }, [optionList, filterText]);

  const isOptionSelected = useCallback(
    (option: Option<string | number>) => {
      if (!Array.isArray(selectedOptions)) {
        return selectedOptions === option.value;
      }
      return selectedOptions.includes(option.value as string);
    },
    [selectedOptions]
  );

  const selectedValue =
    typeof value === "string" || typeof value === "number"
      ? value !== ""
        ? [value]
        : []
      : (value as string[]);

  return (
    <Box className="modal-selector-container">
      <Box
        className={`modal-selector-selected-options ${
          (value.length || typeof value === "number") && showDashedBorder
            ? "dashed-left-border"
            : ""
        }`}
      >
        <Wrap className="modal-selector-tag-container">
          {selectedValue.map((val) => (
            <Tag
              key={val}
              className="app-tags app-tag-selected"
              variant="solid"
            >
              <TagLabel>{tagLabelValue(val)}</TagLabel>
              <TagRightIcon
                onClick={removeSelectedOption(val)}
                as={SmallCloseIcon}
              />
            </Tag>
          ))}
        </Wrap>
        {value.length || typeof value === "number" ? (
          <Flex className="modal-selector-edit-btn-group">
            <Button className="app-btn-link" onClick={showModal}>
              Edit
            </Button>
            {isClearable && (
              <Button className="app-btn-link" onClick={clearAll}>
                Clear
              </Button>
            )}
          </Flex>
        ) : (
          <Button
            className="app-btn-link"
            isDisabled={(envIsDisabled && isConsolePage) || checkEntitlement}
            onClick={showModal}
          >
            {linkName}
          </Button>
        )}
      </Box>
      <AppModal
        customClass="app-modal-selector"
        ref={modalRef}
        modalTitle={modalTitle}
        {...modalSelectorBtnProps}
        primaryBtnSelect={onSave}
        secondaryBtnSelect={onCancel}
        primaryBtnDisabled={
          !selectedOptions.length && typeof selectedOptions !== "number"
        }
      >
        <Box>
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </InputGroup>
          </Box>
          <Flex className="modal-selector-option-flex">
            <Text className="modal-selector-option" fontWeight={700}>
              {optionsTitle || "Options"}
            </Text>
            <Text className="modal-selector-description" fontWeight={700}>
              {optionsDescTitle || "Description"}
            </Text>
          </Flex>
          <Box height="50vh" overflow="auto">
            {filteredOptions.length ? (
              filteredOptions.map((option) => (
                <Flex
                  key={option.value}
                  className={`modal-selector-option-flex ${
                    isOptionSelected(option) ? "option-selected" : ""
                  }`}
                  onClick={_handleSelect(option.value as string)}
                >
                  <Text className="modal-selector-option">
                    {isSingleSelect ? (
                      option.value
                    ) : (
                      <Checkbox isChecked={isOptionSelected(option)}>
                        {option.value}
                      </Checkbox>
                    )}
                  </Text>
                  <Text className="modal-selector-description">
                    {option.label}
                  </Text>
                </Flex>
              ))
            ) : (
              <Center mt={10}>No Results Found.</Center>
            )}
          </Box>
        </Box>
      </AppModal>
    </Box>
  );
}
