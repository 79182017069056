import { HStack, Spacer, Text, Image, Flex } from "@chakra-ui/react";
import { MultiTieredSideMenuDataModel } from "../MultiTieredSideMenuDataModel";
import {
  docCenterSideMenuChevRight,
  docCenterSidemenuChevRightSelected,
} from "public/assets";
import { Dispatch, SetStateAction, useState } from "react";
import Link from "next/link";
import "./MultiTieredSideMenuItem.scss";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Menu Item
interface MultiTieredSideMenuItemProps {
  keyName: number;
  data: MultiTieredSideMenuDataModel;
  onSelect: (item: MultiTieredSideMenuDataModel) => void;
  onTextSelect: (item: MultiTieredSideMenuDataModel) => void;
  column: number;
  isSideMenuOpen: boolean;
  isFromAPILibrary: boolean;
  serviceEndpoint?: string;
  isDrawerOpen: boolean;
  isLastColumn: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setParentDocClick: (id: string) => void;
}

const MenuItem = ({
  keyName,
  data,
  onSelect,
  onTextSelect,
  isSideMenuOpen,
  isFromAPILibrary,
  serviceEndpoint,
  isDrawerOpen,
  isLastColumn,
  setParentDocClick,
}: MultiTieredSideMenuItemProps) => {
  const { isSelected, title } = data;

  const shouldApplyFirstTwoColumnSelectionStyle = () => {
    return isSelected && !doesNotContainChildrenNodes();
  };
  const doesNotContainChildrenNodes = () => {
    return data.childrens.length === 0;
  };
  const [isHovering, setHovering] = useState<boolean>(false);
  const isPathPresent = data.path.length > 0;
  const isChildrensPresent = data.childrens.length > 0;

  return (
    <HStack
      key={keyName}
      onClick={(_) =>
        isChildrensPresent && !isPathPresent
          ? onSelect(data)
          : onTextSelect(data)
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={`
        main-container-item 
        ${isSideMenuOpen ? `side-menu-open` : `side-menu-closed`} 
        ${doesNotContainChildrenNodes() && isSelected ? "enable-back-color" : ""}
        ${isSelected && (!isDrawerOpen || isLastColumn) ? "enable-back-color" : ""}
        ${isHovering ? "enable-hover-color" : ""}
        ${shouldApplyFirstTwoColumnSelectionStyle() && isDrawerOpen && !isLastColumn ? "enable-selection-borders-first-two-column" : "disable-selection-borders-first-two-column"}
        ${!data.icon ? "is-home-item" : ""}
        `}
    >
      {data.icon && (
        <Image className="icon-left" src={data.icon.src} alt="homeIcon" />
      )}

      {isPathPresent && isSideMenuOpen ? (
        <Link
          className="item-link"
          href={data.path}
          onClick={() => setParentDocClick(data.uid)}
        >
          {title}
        </Link>
      ) : (
        <Text
          className={`item-title ${isSelected && isDrawerOpen ? "enable-selection-borders-first-two-column" : ""}`}
          hidden={!isSideMenuOpen}
        >
          {title}
        </Text>
      )}

      {isChildrensPresent && isSideMenuOpen && (

        <div
          className='icon-chevron-right'
          onClick={(_) => onSelect(data)}
          style={{
            display: !isSideMenuOpen ? 'none' : '',
            color: isSelected ? '#0B69FF' : undefined,
          }}
          >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </HStack>
  );
};

const MultiTieredSideMenuItem = (props: MultiTieredSideMenuItemProps) => {
  const { isSideMenuOpen, data, serviceEndpoint, setIsDrawerOpen } = props;
  return (
    <>
      {isSideMenuOpen && (
        <>
          {data.childrens.length === 0 ? (
            // for Core Servicing in Global nav(if link is there in serviceEndpoint then redirecting to link or else Core Servicing is not shown in global nav)
            data.title === "Core servicing" ? (
              <Link
                href={serviceEndpoint!}
                onClick={() => setIsDrawerOpen(false)}
              >
                <MenuItem {...props} />
              </Link>
            ) : (
              <Link
                href={data.path}
                className="item-link"
                onClick={() => setIsDrawerOpen(false)}
              >
                <MenuItem {...props} />
              </Link>
            )
          ) : (
            <MenuItem {...props} />
          )}
        </>
      )}
      {!isSideMenuOpen && (
        <>
          {data.path === ROUTE_BASE_URL.DASHBOARD_PATH ||
          data.path === ROUTE_BASE_URL.DOCUMENT_CENTER ||
          data.path === ROUTE_BASE_URL.API_LIBRARY ? (
            <Link
              href={data.path}
              className="item-link"
              onClick={() => setIsDrawerOpen(false)}
            >
              <MenuItem {...props} />
            </Link>
          ) : null}
        </>
      )}
    </>
  );
};

export default MultiTieredSideMenuItem;
