"use client";
import {
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Box,
  Flex,
  IconButton,
  ListItem,
  List,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Accordion,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useState, useMemo, Fragment } from "react";
import "./CustomTable.scss";
import Image from "next/image";
import {
  upArrow,
  upDownArrow,
  expandDownIcon,
  expandUpIcon,
} from "public/assets";
import { ArrowBackIcon, ArrowForwardIcon } from "@/components/ChakraUiManager";
import { SelectDropdown } from "@/components/common";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";

type TableProps = {
  data: {}[];
  columns: { header: string; accessorKey: string }[];
  renderDataCell: (
    value: any,
    key: any,
    row?: any,
    index?: number
  ) => React.ReactNode | any;
  className?: string;
  isPagination?: boolean;
  showFilter?: boolean;
  filterColumn?: string;
  filterId?: string;
  pageSizeData?: number;
  renderHeaderCell?: (value: any, key: any) => React.ReactNode | any;
  isAccordion?: boolean;
  accordionContent?: (row?: any) => React.ReactNode | any;
  isRowSelected?: (id?: any) => boolean;
  getLink?: (data?: any) => string;
};

const CustomTable = (props: TableProps) => {
  const {
    data,
    columns,
    renderDataCell,
    className,
    isPagination,
    showFilter,
    filterColumn,
    filterId,
    pageSizeData = 10,
    renderHeaderCell,
    isAccordion = false,
    accordionContent = null,
    isRowSelected = null,
    getLink,
  } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: pageSizeData, //default page size
  });
  let tableConfig: any = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: (row: any, columnId: string, filterValue: string[]) => {
      return filterValue[0]
        ? filterValue.includes(row.getValue(columnId))
        : true;
    },
    state: {
      sorting,
      globalFilter,
    },
    autoResetAll: false,
  };
  if (isPagination) {
    tableConfig.getPaginationRowModel = getPaginationRowModel();
    tableConfig.state = {
      ...tableConfig.state,
      pagination,
    };
    tableConfig.onPaginationChange = setPagination;
  }

  const handleFilterChange = (val: string[]) => {
    setPagination(() => {
      return {
        pageIndex: 0, //initial page index
        pageSize: pageSizeData, //default page size
      };
    });
    setGlobalFilter(val);
  };

  const filterOptionList: string[] = useMemo(() => {
    const allNames = data.map((item) =>
      filterColumn !== undefined
        ? item[`${filterColumn}` as keyof typeof item]
        : ""
    );
    const filteredNames = allNames.filter((val) => val != "" && val != null);
    return Array.from(new Set(filteredNames));
  }, [data, filterColumn]);

  const PaginationPanel = () => {
    return (
      table.getPageCount() > 1 && (
        <Flex justifyContent="space-between" className="pagination-panel">
          <IconButton
            isRound={true}
            aria-label="Done"
            fontSize="3rem"
            icon={<ArrowBackIcon />}
            variant="outline"
            p={"3px"}
            size={"xl"}
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="pag-nav-btn"
          />
          <Box>{constructPageNumberElem()}</Box>
          <IconButton
            isRound={true}
            variant="outline"
            aria-label="Done"
            fontSize="3rem"
            icon={<ArrowForwardIcon />}
            p={"3px"}
            size={"xl"}
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
            className="pag-nav-btn"
          />
        </Flex>
      )
    );
  };
  const constructPageNumberElem = () => {
    let content = [];
    const pageCount = table.getPageCount();
    const currentPageIndex = table.getState().pagination.pageIndex;
    if (pageCount <= pageSizeData) {
      for (let i = 1; i <= pageCount; i++) {
        content.push(
          <ListItem
            onClick={() => table.setPageIndex(i - 1)}
            className={
              table.getState().pagination.pageIndex + 1 === i ? "active" : ""
            }
            key={i}
          >
            {i}
          </ListItem>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        content.push(
          <ListItem
            onClick={() => table.setPageIndex(i - 1)}
            className={
              table.getState().pagination.pageIndex + 1 === i ? "active" : ""
            }
            key={i}
          >
            {i}
          </ListItem>
        );
      }

      if (currentPageIndex + 1 > 5 && currentPageIndex + 1 < pageCount - 4) {
        content.push(<ListItem>..........</ListItem>);
        content.push(
          <ListItem
            onClick={() => table.setPageIndex(currentPageIndex)}
            className={
              table.getState().pagination.pageIndex === currentPageIndex
                ? "active"
                : ""
            }
            key={currentPageIndex + 1}
          >
            {currentPageIndex + 1}
          </ListItem>
        );
        content.push(<ListItem>..........</ListItem>);
      } else {
        content.push(<ListItem>....................</ListItem>);
      }
      for (let i = pageCount - 4; i <= pageCount; i++) {
        content.push(
          <ListItem
            onClick={() => table.setPageIndex(i - 1)}
            className={
              table.getState().pagination.pageIndex + 1 === i ? "active" : ""
            }
            key={i}
          >
            {i}
          </ListItem>
        );
      }
    }
    return (
      <List spacing={5} className="pagination-number-panel">
        {content}
      </List>
    );
  };
  const table = useReactTable(tableConfig);
  return (
    <>
      {showFilter && (
        <div className="ct-multiselect">
          <SelectDropdown
            isMulti
            dropdownList={filterOptionList}
            value={globalFilter}
            onChange={handleFilterChange}
            hideDashedBorder
            placeholder="Filter By"
            customClass="all-documents-filter"
            id={filterId}
          />
        </div>
      )}
      <Table className={`custom-table-container ${className}`}>
        <Thead className="custom-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Td
                    className="custom-table-header-data"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Box className="custom-table-header-data-item">
                      {renderHeaderCell
                        ? renderHeaderCell(
                            header.column.columnDef.header,
                            header
                          )
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {header.column.columnDef.enableSorting ? (
                        header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? (
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              style={{ height: '16px', width: '16px' }}
                              aria-label="sorted descending"
                              title="Sorted Descending"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              style={{ height: '16px', width: '16px' }}
                              aria-label="sorted ascending"
                              title="Sorted Ascending"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon
                            icon={faArrowUpArrowDown}
                            style={{ height: '16px', width: '16px' }}
                            aria-label="sortable"
                            title="Sortable"
                          />
                        )
                      ) : null}
                    </Box>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Thead>

        {/*
          Table output starts here.
        */}

        {!isAccordion && !getLink && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                className={`custom-table-data-row ${isRowSelected && isRowSelected(row?.original) ? "row-selected" : ""}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell, index) => {
                  const isLastElement =
                    index === row.getVisibleCells().length - 1;
                  return (
                    <Td
                      className={`custom-table-data-row ${isLastElement ? `is-last-element` : ``}`}
                      key={cell.id}
                      textOverflow={"ellipsis"}
                    >
                      {renderDataCell(
                        cell.getValue(),
                        cell.column.id,
                        cell.row.original,
                        cell.row.index
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}

        {getLink && !isAccordion && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                className={`custom-table-data-row ${isRowSelected && isRowSelected(row?.original) ? "row-selected" : ""}`}
                key={row.id}
              >
                {row.getVisibleCells().map((cell, index) => {
                  const isLastElement =
                    index === row.getVisibleCells().length - 1;

                  const rowData = cell.row.original as any;
                  const linkUrl = getLink(rowData);

                  return (
                    <Td
                      className={`custom-table-data-row ${isLastElement ? "is-last-element" : ""}`}
                      key={cell.id}
                      textOverflow="ellipsis"
                    >
                      <a href={linkUrl}>
                        {renderDataCell(
                          cell.getValue(),
                          cell.column.id,
                          cell.row.original,
                          cell.row.index
                        )}
                      </a>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}

        {isAccordion && !getLink && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Accordion as={Fragment} key={row.id} allowToggle>
                <AccordionItem as={Fragment}>
                  {({ isExpanded }) => (
                    <>
                      <Tr className={isExpanded ? "row-expanded" : ""}>
                        {row.getVisibleCells().map((cell, index) => {
                          const isLastElement =
                            index === row.getVisibleCells().length - 1;

                          if (isLastElement) {
                            return (
                              <Td
                                className="custom-table-data-row"
                                key={cell.id}
                              >
                                <AccordionButton
                                  className={"custom-accordion-item-button"}
                                >
                                  {isExpanded ? (
                                    <Image
                                      className={"accordion-expand"}
                                      src={expandUpIcon}
                                      alt="expand up icon"
                                    />
                                  ) : (
                                    <Image
                                      className={"accordion-expand"}
                                      src={expandDownIcon}
                                      alt="expand down icon"
                                    />
                                  )}
                                </AccordionButton>
                              </Td>
                            );
                          }

                          return (
                            <Td
                              className={`custom-table-data-row ${isLastElement ? `is-last-element` : ``}`}
                              key={cell.id}
                              textOverflow={"ellipsis"}
                            >
                              {renderDataCell(
                                cell.getValue(),
                                cell.column.id,
                                cell.row.original,
                                cell.row.index
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                      <tr className={isExpanded ? "acc-row-expanded" : ""}>
                        <td colSpan={5}>
                          <AccordionPanel as={Fragment}>
                            {row.original &&
                              accordionContent &&
                              accordionContent(row.original)}
                          </AccordionPanel>
                        </td>
                      </tr>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            ))}
          </Tbody>
        )}
      </Table>
      {table.getRowModel().rows.length == 0 ? (
        <Box className="data-found-message">No data found</Box>
      ) : (
        ""
      )}
      {isPagination && <PaginationPanel />}
    </>
  );
};

export default CustomTable;
