import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  useDisclosure,
  CloseButton,
  Button,
  Icon,
} from "../../ChakraUiManager";
import "./ToastAlert.scss";

type toastAlertProps = {
  status: "success" | "info" | "warning" | "error" | "loading";
  title: string;
  description: string;
  bgIconColor?: "default" | "green" | "yellow" | "red";
  isDisabled?: boolean;
  icon?: React.ComponentType;
};

export default function ToastAlert({
  status,
  title,
  description,
  bgIconColor = "default",
  isDisabled = false,
  icon: IconComponent,
}: toastAlertProps) {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });

  function getIconClass(status: string) {
    switch (status) {
      case "success":
        return "green-icon";
      case "warning":
        return "yellow-icon";
      case "info":
        return "blue-icon";
      case "error":
        return "red-icon";
      case "loading":
        return "blue-icon";
      default:
        return "";
    }
  }

  const renderIcon = () => {
    const customIconClass =
      bgIconColor === "yellow" ? "custom-icon-yellow" : "custom-icon";
    if (bgIconColor === "default") {
      return <AlertIcon className={`alert-icon ${getIconClass(status)}`} />;
    } else if (bgIconColor === "yellow") {
      return (
        <div className={customIconClass}>
          <div className="circle">
            <Icon as={IconComponent} className="icon-circle-style-1" />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={customIconClass}
          style={{
            backgroundColor: bgIconColor,
            border: `0.2rem solid ${bgIconColor}`,
          }}
        >
          <div className="circle">
            <Icon
              as={IconComponent}
              color={bgIconColor}
              className="icon-circle-style"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {isVisible ? (
        <Alert
          status={status}
          className={`alert-container${isDisabled ? " disabled" : ""}`}
          style={
            bgIconColor === "yellow"
              ? { border: `0.1rem solid #ff8606` }
              : { border: `0.1rem solid ${bgIconColor}` }
          }
        >
          <div className="alert-header">
            {renderIcon()}
            <Box className="box-padding">
              <AlertTitle>{title}!</AlertTitle>
              <AlertDescription>{description}</AlertDescription>
            </Box>
          </div>
          <div className="alert-header-second">
            <a href="/" className="link-toast-icon">
              Link
            </a>
            <p className="vertical-line"></p>
            <CloseButton className="close-button-toast" onClick={onClose} />
          </div>
        </Alert>
      ) : (
        <Button onClick={onOpen}>Show Alert</Button>
      )}
    </>
  );
}
