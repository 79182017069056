import { ROUTE_PATH } from "@/route-config/route-path";
import { AppRadioProps } from "../common/form-fields/radio-button-group/RadioButtonGroup";
import {
  DESCRIPTION_KEY,
  GROUP_KEY,
  LAUNCH_QUICK_START_KEY,
  NAME_KEY,
  PROD_TYPE_KEY,
  SUBTYPE_KEY,
} from "../common/data/custom-table-constants";
import { CRT_ENTITY, GL_ENTITY, IC_ENTITY } from "@/utils/constants";

export const DEFAULT_INDEX_MATRIX = [
  "Bank_Savings_Rate",
  "Fedwire_Cutoff",
  "Fedwire_Fees",
  "LOB_CD_PenMat",
  "LoanRate",
  "LocRate",
  "PerSavGISetMatrix",
  "PosnAge_InTerm",
  "Term_prodSubType",
  "achMatrix",
  "contribLimits",
  "glFilterMatrix",
  "nwkSuspenseMatrix",
  "scraMatrix",
  "sysSuspenseMatrix",
];

export const TERM_DROPDOWN_OPTIONS = [
  "Day",
  "Business day",
  "Month",
  "Year",
];

export const CALCULATION_METHOD = [
  "Actual/Actual",
  "30/360",
  "Actual/365",
  "Actual/360",
];
export const LOAN_CALCULATION_METHOD = [
  ...CALCULATION_METHOD,
  "Adjusted 30/365",
  "Adjusted 30/360",
];

export const CALCULATION_ACCURAL = [
  "General Ledger Balance",
  "Collected Balance",
  "Real Time Balance",
];
export const LOAN_CALCULATION_ACCURAL = [
  ...CALCULATION_ACCURAL,
  "Scheduled Balance",
];

export const EXISTING_FEE: string[] = [
  "ACH_Return_Fee_In",
  "ACH_Return_Fee_Out",
  "ACH_Same_Day",
  "Check_Orig",
  "Check_Ret_Fee_In",
  "Check_Ret_Fee_Out",
  "FWCOVERFEE",
  "FWDRCPAYFEE",
  "FW Manual",
  "FWORIGFEE",
  "FWRTRNPAYFEE",
  "foreign_atm_fee",
  "foreign_pos_fee",
  "late_charge",
  "prePmt_fee",
];

export const INTERESTCALCULATEDOPTION: string[] = [
  "Accrued amount at the time of receivable generation",
  "Anticipated through payment due date, at the time of receivable generation",
  "Fixed amount",
  "Accrued amount as of repayment",
  "Calculated amount as per amortization schedule",
  "Waive accrued interest, if balance as of last receivable paid in full",
  "Waive accrued interest, if balance as of last receivable paid in full, else bill it",
];

export const NegativeAccuredOption: AppRadioProps<any>[] = [
  {
    label: "negAccr > accrint - carry remainder forward",
    value: "1",
  },
  {
    label: "negAccr > accrint + principal bal - carry remainder forward",
    value: "2",
  },
  {
    label:
      "negAccr > accrint + principal bal - deduct remainder from principal balance",
    value: "3",
  },
];
export const InterestAccrualOption: AppRadioProps<any>[] = [
  {
    label: "Carry forward interest remainder",
    value: "1",
  },
  {
    label:
      "Clear negative accrual remainder, carry forward positive accrual remainder",
    value: "2",
  },
  {
    label: "Clear accrual remainder",
    value: "3",
  },
  {
    label: "N/A",
    value: "4",
  },
];

export const PAYOUT_OPTION_PROGRAM_TYPE = {
  dropdownList: [
    "Adjust interest accrued if deposit amount is met during deposit interval",
    "Credit reward amount if goal amount is attained as of goal expiration date",
    "Credit reward amount if goal amount is met during any transaction prior to the goal expiration date",
    "Credit reward amount if the position remains open through the expiration date",
    "Adjust interest rate at accrual if qualifying",
    "Adjust interest rate at rollover if qualifying",
    "Add bonus rate at rollover",
  ],
  dropdownName: "",
  selectedOptionsTitle: "Selected Option",
  placeholder: "Search Option",
};

export const EARLYWITHDRAWALPENALTYOPTION: AppRadioProps<any>[] = [
  {
    label: "Draw a penalty against principle balance",
    value: "1",
  },
  {
    label: "Draw a penalty against accrued interest first",
    value: "2",
  },
];

export const GENERAL_LEDGER_SET_CODE: AppRadioProps<any>[] = [
  {
    label: "Leverage existing GL set code",
    value: "1",
  },
  {
    label:
      "Clear negative accrual remainder, carry forward positive accrual remainder",
    value: "2",
  },
];
export const PROD_BASICS_GEN_LED_COUPLE_RADIO = [
  "BusSavgreat100k - Business Savings > 100k GL Set Code",
  "BusSavless100k - Business Savings < 100k GL Set Code",
  "CD100k-250k - CD 100k-250k GL Set Code",
  "CDgreater250k - CD <250k GL Set Code",
  "CDless100k - CD < 100k Set Code",
  "ChkIntGlSet- Daily Interest Checking GL Set",
  "PerSavgreat100k - Personal Savings > 100k GL Set Code",
  "PerSavless100k - Personal Savings < 100k GL Set Code",
  "loansGlSet - loans GL Set Code",
];
export const BASIC_PRODUCT_OPERTAIONAL_DATE = [
  "Bank_Savings_Rate",
  "Fedwire_Cutoff",
  "Fedwire_Fees",
  "LOB_CD_PenMat",
  "LoanRate",
  "LocRate",
  "PerSavGISEtMatrix",
  "PosnAge_InTerm",
  "Term_prodSubType",
  "achMatrix",
  "contribLimits",
  "glFilterMatrix",
  "nwklSuspenseMatrix",
  "scraMatrix",
  "sysSuspenseMatrix",
];
export const PAYMENT_CHARGES_MULTI_SELECT_DROPDOWN = [
  "Principal",
  "Interest",
  "Late charge",
  "Fee",
  "Fee - return fee for incoming returned items",
  "Fee - return fee for outgoing returned items",
  "Fee - ACH same day settlement fee",
  "Fee - check issue",
  "Fee - Foreign ATM fee",
  "Fee - Foreign POS fee",
  "Fee - fedwire cover payment origination fee",
  "Fee - fedwire drawdown payment fee ",
  "Fee - fedwire manual fee ",
  "Fee - fedwire origination fee",
  "Fee - fedwire return payment fee",
  "Fee - late charge ",
  "Fee - prepayment fee",
  "Cap Interest",
];

export const PAYMENT_CHARGES_EXTENDED_RADIO_BUTTONS = [
  {
    label: "Vertical",
    value: "vertical",
  },
  {
    label: "Horizontal",
    value: "horizontal",
  },
];

export const PAYMENT_DIRECTION_RADIO_OPTIONS = [
  {
    label: "Priority",
    value: 1,
  },
  {
    label: "Past",
    value: 2,
  },
  {
    label: "Current",
    value: 3,
  },
  {
    label: "N/A",
    value: 4,
  },
];

export const COLLATERAL_SECURITY_LEVEL_RADIO_OPTIONS: AppRadioProps<any>[] = [
  {
    label: "Secured",
    value: "secured",
  },
  {
    label: "Unsecured",
    value: "unsecured",
  },
  {
    label: "Partially",
    value: "partially",
  },
  {
    label: "Margin",
    value: "margin",
  },
];

export const CALCULATINGPRINCIPALPAYMENTSOPTION: AppRadioProps<any>[] = [
  { label: "Percentage Balance", value: "Percentage Balance" },
  {
    label: "Fixed Payment Amount Less Interest",
    value: "Fixed Payment Amount Less Interest",
  },
  { label: "Fixed Amount", value: "Fixed Amount" },
];

export const BASEVALUEOPTION: AppRadioProps<any>[] = [
  { label: "Credit Limit", value: "Credit Limit" },
  {
    label: "Real-time Balance",
    value: "Real-time Balance",
  },
];

export const OVERDRAFT_PROTECTION_START_OPTION: AppRadioProps<any>[] = [
  {
    label: "Immediate",
    value: "1",
  },
  {
    label: "Funding Date",
    value: "2",
  },
  {
    label: "Funding Expression Date",
    value: "3",
  },
  {
    label: "Duration from Open Date",
    value: "4",
  },
];
export const SELECTED_CHARGE_OFF_OPTION: AppRadioProps<any>[] = [
  {
    label: "Charge off full amount and close account",
    value: "1",
  },
  {
    label: "Charge off partial amount, balance remains, and account stays open",
    value: "2",
  },
];

export const PAYMENTTOLERANCETYPE: string[] = [
  "Payment tolerance amount",
  "Payment tolerance percentage",
];

export const ADVANCEDPAYMENTAPPLICATIONMETHOD: AppRadioProps<any>[] = [
  { label: "Principal prepayment", value: "Principal prepayment" },
  {
    label: "Generate and pay advance receivable",
    value: "Generate and pay advance receivable",
  },
  {
    label: "Accrued interest then principal",
    value: "Accrued interest then principal",
  },
  {
    label: "Principal then accrued interest",
    value: "Principal then accrued interest",
  },
];

export const MATURITY_OPTION: AppRadioProps<any>[] = [
  {
    label: "Capitalize interest and rollover",
    value: "Capitalize interest and rollover",
  },
  {
    label: "Transfer principal and interest",
    value: "Transfer principal and interest",
  },
  {
    label: "Rollover principal; transfer interest",
    value: "Rollover principal; transfer interest",
  },
  {
    label: "Capitalize interest: remain on deposit",
    value: "Capitalize interest: remain on deposit",
  },
  {
    label: "Partial transfer",
    value: "Partial transfer",
  },
];

export const PRODUCT_STATUS = {
  not_started: "not_started",
  in_progress: "in_progress",
  completed: "completed",
};

//table header column mapping
//table row key should be named as accessorKey
export const QUICKSTART_CONFIG_TABLE_HEADER_COLUMNS = [
  { header: "Name", accessorKey: NAME_KEY, enableSorting: true },
  { header: "Description", accessorKey: DESCRIPTION_KEY, enableSorting: false },
  { header: "Type", accessorKey: PROD_TYPE_KEY, enableSorting: true },
  { header: "Subtype", accessorKey: SUBTYPE_KEY, enableSorting: true },
  { header: "Group", accessorKey: GROUP_KEY, enableSorting: true },
  { header: "", accessorKey: LAUNCH_QUICK_START_KEY, enableSorting: false },
];

export type ProgressStatus = {
  id: string;
  title: string;
  text: string;
  href: string;
};

//IC Summary Landing page
export const IC_PROGRESS_STATUS_JSON: ProgressStatus[] = [
  {
    id: IC_ENTITY.account_group,
    title: "Establish Account Group",
    text: "Segment accounts for streamlined policy implementation and management",
    href: ROUTE_PATH.IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS,
  },
  {
    id: IC_ENTITY.asset_catalog,
    title: "Configure the asset catalog",
    text: "Define asset classes and identifiers to establish transactional currencies and maintain regulatory compliance",
    href: ROUTE_PATH.IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS,
  },
  {
    id: IC_ENTITY.account_type,
    title: "Establish account types",
    text: "Customize account types to enhance product offerings and ensure compliance",
    href: ROUTE_PATH.IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS,
  },
  {
    id: IC_ENTITY.financial_calendar,
    title: "Set up financial calendars",
    text: "Align events with operational and fiscal cycles for compliance and efficiency",
    href: ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS,
  },
  {
    id: IC_ENTITY.financial_organization,
    title: "Configure your financial institution",
    text: "Define key attributes for your institution for smooth operations and regulatory compliance",
    href: ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,
  },
  {
    id: IC_ENTITY.bank_parameters,
    title: "Configure financial institution (bank) parameters",
    text: "Configure operational parameters to meet institutional needs and goals",
    href: ROUTE_PATH.IC_BANK_PARAMS_FOUNDATION_DETAILS,
  },
];

//GL Summary Landing page
export const GL_PROGRESS_STATUS_JSON: ProgressStatus[] = [
  {
    id: GL_ENTITY.accounting_segments,
    title: "Accounting segments",
    text: "Define the hierarchical structure of your general ledger and enhance reporting",
    href: ROUTE_PATH.GL_ACCOUNTING_SEGMENTS,
  },
  {
    id: GL_ENTITY.accounts,
    title: "Accounts",
    text: "Establish the chart of accounts that tracks money movement according to your institutional accounting process",
    href: ROUTE_PATH.GL_ACCOUNTS,
  },
  {
    id: GL_ENTITY.system_accounts,
    title: "System accounts",
    text: "Establish accounts used for system processing",
    href: ROUTE_PATH.GL_SYSTEM_ACCOUNTS,
  },
  {
    id: GL_ENTITY.sets,
    title: "Sets",
    text: "Manage the transactional and balance-related activities of a particular position",
    href: ROUTE_PATH.GL_SETS,
  },
  {
    id: GL_ENTITY.positions,
    title: "Positions",
    text: "Review the transactional units within general ledger accounts to examine activities at a more granular level",
    href: ROUTE_PATH.GL_POSITIONS,
  },
];
//CRT Summary Landing page
export const CRT_PROGRESS_STATUS_JSON: ProgressStatus[] = [
  {
    id: CRT_ENTITY.general_setup,
    title: "General setup",
    text: "Identify and articulate the nature of customer relationships for reporting and data validation purposes",
    href: ROUTE_PATH.CRT_GENERAL_SETUP,
  },
  {
    id: CRT_ENTITY.party_relationships,
    title: "Party relationships",
    text: "Set relationship types, roles, and party limits to structure how parties relate within the system, enhancing relationship management",
    href: ROUTE_PATH.CRT_PARTY_RELATIONSHIPS,
  },
];
export const RESOURCE_CONTENT = [
  {
    title: "Lorem Ipsum Dolor Sed",
    extra: "Extra caption",
    link: "",
  },
  {
    title: "Lorem Ipsum Dolor Sed",
    extra: "Extra caption",
    link: "",
  },
  {
    title: "Lorem Ipsum Dolor Sed",
    extra: "Extra caption",
    link: "",
  },
  {
    title: "Lorem Ipsum Dolor Sed",
    extra: "Extra caption",
    link: "",
  },
];

// Form validation
export const ERROR_MESSAGE = "This field is required";

//Common Interface For Get Data
export interface GET_DROPDOWN_OPTION_FROM_MATRIX_TYPE {
  indexNameOptions: string[];
}
export interface INTERNAL_SCHEMA_TYPE<T> {
  label: string;
  value: T;
}

//Academy- table header for instructor led session
export const INSTRUCTOR_SESSION_CONFIG_TABLE_HEADER = [
  {
    header: "Course",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
  },
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "",
    accessorKey: "id",
    enableSorting: false,
  },
];

export const DASHBORAD_RESOURCE_CONTENT = [
  {
    title: "Introduction to Finxact",
    extra:
      "Learn about Finxact’s value offering and how it can propel your institution",
    link: "",
  },
  {
    title: "Finxact Certification Program",
    extra:
      "Learn about our Certification Program and how it can advance your career",
    link: "",
  },
  {
    title: "Introduction to Console",
    extra:
      "Learn about the key concepts for navigating and operating in Console",
    link: "",
  },
];
