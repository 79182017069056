"use client";
import { useEffect, useState } from "react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import "./RangeSliderComponent.scss";
import { Text, Tooltip, Flex, Button, Switch, Stack } from "@chakra-ui/react";
import PercentageInput from "../percentage-input/PercentageInput";
import InfoToolTip from "../../../../public/assets/images/tootlip.png";
export interface RangeSliderProps {
  componentLabel?: string;
  minRangeLabel?: string;
  maxRangeLabel?: string;
  defaultMinRange?: number;
  defaultMaxRange?: number;
  minRangeValue?: number;
  maxRangeValue?: number;
  minRangeDisplayValue?: number;
  maxRangeDisplayValue?: number;
  stepRange?: number;
}
export default function RangeSliderComponent(props: RangeSliderProps) {
  const {
    componentLabel,
    minRangeLabel="",
    maxRangeLabel="",
    minRangeValue,
    maxRangeValue,
    defaultMinRange,
    defaultMaxRange,
    minRangeDisplayValue,
    maxRangeDisplayValue,
    stepRange,
  } = props;
  const [limit, setLimit] = useState([0.2, 0.8]);
  const onChange = (val: any) => {
    setLimit(val);
  };

  const formatPercentage = (value: any) => `${Math.round(value * 100)}%`;
  const [minpercentage, setMinPercentage] = useState(defaultMinRange); // Display value on Tooltip
  const [maxpercentage, setMaxPercentage] = useState(defaultMaxRange); // Display value on Tooltip

  const [leftThumbValue, setLeftThumbValue] = useState<any>(defaultMinRange); // Set value on Range slider
  const [rightThumbValue, setRightThumbValue] = useState<any>(defaultMaxRange); // Set value on Range slider

  useEffect(() => {
    setLeftThumbValue(minpercentage);
  }, [minpercentage]);

  useEffect(() => {
    setRightThumbValue(maxpercentage);
  }, [maxpercentage]);

  const handleMinPercentageChange = (newValue: any) => {
    setMinPercentage(newValue);
  };
  const handleMaxPercentageChange = (newValue: any) => {
    setMaxPercentage(newValue);
  };

  const handleRangeSliderChange = (values: any) => {
    setMinPercentage(values[0]);
    setMaxPercentage(values[1]);
  };

  return (
    <>
      <Flex className="range-slider-outer-outer-text">
        <Flex className="range-slider-outer-inner-text">
          <h6 className="title-range-slider">{componentLabel}</h6>
          <p className="info-img-range-slider">
            <span>
              <img src={InfoToolTip.src} alt="info"></img>
            </span>
          </p>
        </Flex>

        <Flex className="outer-slider-flex">
          <Flex className="outer-slider-flex">
            <PercentageInput
              label={minRangeLabel}
              value={minpercentage}
              onChange={handleMinPercentageChange}
            />
            <PercentageInput
              label={maxRangeLabel}
              value={maxpercentage}
              onChange={handleMaxPercentageChange}
            />
          </Flex>
          <Flex className="flex-slider-item">
            <RangeSlider
              aria-label={["min", "max"]}
              value={[leftThumbValue, rightThumbValue]}
              onChange={handleRangeSliderChange}
              onChangeEnd={(val) => console.log(val)}
              min={minRangeValue}
              max={maxRangeValue}
              step={stepRange}
              className="range-slider-value-custom"
            >
              <RangeSliderTrack className="range-slider-custom">
                <RangeSliderFilledTrack
                  bg="#FB6400;"
                  defaultValue={(rightThumbValue - leftThumbValue) / 100}
                />
              </RangeSliderTrack>
              <Tooltip
                label={formatPercentage(minpercentage)}
                className="slider-second-tooltip"
                placement="top"
                isOpen
              >
                <RangeSliderThumb
                  index={0}
                  className="first-slider-thumb"
                  sx={{
                    ":focus": {
                      boxShadow: "0 0 3px #fff",
                    },
                  }}
                ></RangeSliderThumb>
              </Tooltip>

              <Tooltip
                label={formatPercentage(maxpercentage)}
                className="slider-second-tooltip"
                placement="top"
                isOpen
              >
                <RangeSliderThumb
                  index={1}
                  sx={{
                    ":focus": {
                      boxShadow: "0 0 3px #fff",
                    },
                  }}
                  className="range-slider-thumb-css"
                ></RangeSliderThumb>
              </Tooltip>
            </RangeSlider>
            <Flex className="number-slider-text">
              <Text className="number-color">{minRangeDisplayValue}</Text>
              <Text className="number-color">{maxRangeDisplayValue}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
