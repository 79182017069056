import {
  HStack,
  IconButton,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { sideMenuCollapseIcon, sideMenuExpandIcon } from "public/assets";
import { Dispatch, SetStateAction } from "react";
import "./MultiTieredSideMenuToggleHeader.scss";
import { HoverToolTip } from "../..";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons'

interface MultiTieredSideMenuToggleHeaderProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const MultiTieredSideMenuToggleHeader = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
}: MultiTieredSideMenuToggleHeaderProps) => {
  return (
    <HStack
      className={`
        main-container-side-menu-toggle-header 
        ${isSideMenuOpen ? "side-menu-open" : ""}
      `}
    >
      <Spacer />
        <HoverToolTip placement={"right"} label={isSideMenuOpen ? "Collapse" : "Expand"}>
          <IconButton
            className={`icon-button-toggle ${isSideMenuOpen ? "side-menu-open" : ""}`}
            icon={
              isSideMenuOpen ?
                <FontAwesomeIcon icon={faChevronCircleLeft} /> :
                <FontAwesomeIcon icon={faChevronCircleRight} />
            }
            onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
            aria-label="button for toggling side menu"
          />
        </HoverToolTip>
    </HStack>
  );
};

export default MultiTieredSideMenuToggleHeader;
