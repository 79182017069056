const REGEX_PATTERN = {
  RESTRICT_DECIMAL: /^[a-zA-Z0-9]*$/,
  ALPHA_NUMERIC: /^[a-zA-Z0-9]*$/,
  TEXT_WITH_UNDERSCORE: /^[a-zA-Z_]*$/,
  RESTRICT_TO_FIVE_CHARACTER: /^[a-zA-Z0-9]{0,5}$/,
  ONLY_NUMBERS: /^[0-9]*$/,
  URL_VALIDATION: /^https?:\/\/([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,20}(\/.*)?$/,
  ONLY_DOMAIN_VALIDATION: /^@([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,20}$/i,
  DURATION_INPUT: /^\d+[D,d,B,b,M,m,Y,y]{1}$/,
  RESTRICT_TO_NINE_DIGIT: /^\d{9}$/,
  FREQUENCY_INPUT:
    /(\d+[B,b,D,d][A,a,N,n,P,p]?)|(\d+[M,m,Y,y]([1-9]|[12]\d|3[0-1]|[E,e]?)[A,a,N,n,P,p]?$)/,
  PLUS_WITH_FOUR_digit: /^\+?\d{0,4}$/,
  PHONE_NUMBER_REGEX: /^(\+?1\s?)?\+?[0-9-]*\*?#?$/,
  RESTRICT_WITH_TEN_DIGITS: /^\d{0,10}$/,
  RESTRICT_WITH_SIX_DIGITS: /^\d{0,6}$/,
  RESTRICT_DECIMAL_FIVE_DIGITS: /^[0-9]*(\.[0-9]{0,5})?$/,
  RESTRICT_WITH_FOURTEEN_DIGITS: /^\d{0,14}$/,
  EMAIL_ID_REGEX:
    /^[-!#$%&'*+/=?^_`{|}~A-Za-z0-9]+(?:\.[-!#$%&'*+/=?^_`{|}~A-Za-z0-9]+)*@([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]/,
  NOT_STARTING_WITH_DECIMAL: /^(?!0\d)(\d+(\.\d*)?)?$/,
  RESTRICT_TO_THIRTY_FIVE_CHARACTER: /^[a-zA-Z0-9]{0,35}$/,
  ALPHA_NUMERIC_WITH_UNDERSCORE: /^[a-zA-Z0-9_]*$/,
  RESTRICT_DOT_INPUT_TYPE: /^-?(\d*)+\.?\d+$/,
  //For component name
  ALPHA_NUMERIC_WITH_SPECIAL: /^[\w_+-]*$/,
};
export { REGEX_PATTERN };
