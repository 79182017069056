import React from "react";
import {
  Tag,
  TagLabel,
  CheckIcon,
  WarningIcon,
  CloseIcon,
  Flex,
  Icon,
} from "../../ChakraUiManager";
import "./Badges.scss";
import Image from "next/image";

export type BadgesProps = {
  size?: "lg" | "md" | "sm";
  variant?: "subtle" | "outline" | "solid";
  colorScheme?: "gray" | "yellow" | "cyan" | "pink";
  label: string;
  icon?: boolean;
  type?:
    | "default"
    | "success"
    | "warning"
    | "error"
    | "active"
    | "pending"
    | string;
  image?: boolean;
  imageSrc?: any;
  imageAlt?: string;
};

type IconForLabelProps = {
  label: string;
  colorScheme: "gray" | "yellow" | "cyan" | "pink";
};

export default function Badges({
  size = "lg",
  variant = "subtle",
  colorScheme = "gray",
  label,
  icon = false,
  type = "default",
  image = false,
  imageSrc,
  imageAlt = "",
}: BadgesProps) {
  function IconForLabel({ label, colorScheme }: IconForLabelProps) {
    switch (label) {
      case "Completed":
      case "Active":
        return (
          <div className={`icon-circle-${colorScheme}`}>
            <Icon as={CheckIcon} className="tag-icon-styling-completed" />
          </div>
        );
      case "In Progress":
      case "Pending":
        return (
          <Icon
            as={WarningIcon}
            color="#ff8606"
            className="tag-icon-styling-In-Progress"
          />
        );
      case "Not Started":
        return (
          <Icon as={WarningIcon} className="tag-icon-styling-In-Progress" />
        );

      case "Error":
        return <Icon as={WarningIcon} className="tag-icon-styling-error" />;
      case "Close":
        return (
          <div className={`icon-circle-${colorScheme}`}>
            <Icon as={CloseIcon} className="tag-icon-styling-close" />
          </div>
        );
      default:
        return null;
    }
  }

  function getClassByType() {
    switch (type) {
      case "success":
      case "active":
        return "tag-success";
      case "error":
        return "tag-error";
      case "warning":
      case "pending":
        return "tag-warning";
      case "primary":
        return "tag-primary";
    }
  }

  return (
    <Flex direction="column" className="badge-main-container">
      <Tag
        size={size}
        key={size}
        variant={variant}
        borderRadius="full"
        className={`tag-default ${getClassByType()}`}
      >
        {image && <Image className="tag-image" src={imageSrc} alt={imageAlt} />}
        <TagLabel
          className={
            label?.includes("SET UP") || label?.includes("IN PROGRESS")
              ? "tag-label-style"
              : ""
          }
        >
          {label}
        </TagLabel>
      </Tag>
      {icon === true && (
        <div className="tag-icon-styles">
          <IconForLabel label={label} colorScheme={colorScheme} />
        </div>
      )}
    </Flex>
  );
}
