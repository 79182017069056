"use client";

import { Box, Text, Button, Link } from "@/components/ChakraUiManager";
import "./FooterNotLoggedInUser.scss";

function FooterNotLoggedInUser() {
  return (
    <div>
      <Box className="footer-not-logged-in-container">
        <Box className="footer-text">
          <Text as="h2">Discover learning content designed for you</Text>
          <Text as="p">Enroll to access customized docs</Text>
          <Box className="footer-buttons">
            <Button
              className="app-btn-inverse-secondary footer-button-width"
              onClick={() => {
                window.location.href = `/profile-setup`;
              }}
            >
              Enroll now
            </Button>
            <Button
              className="app-btn-reg-secondary footer-button-width"
              onClick={() => {
                window.location.href = `/console/login?returnPage=${window.location.pathname}`;
              }}
            >
              Log in
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default FooterNotLoggedInUser;
