import { Box } from "@/components/ChakraUiManager";
import Image from "next/image";
import { redAlert } from "public/assets";
import "./ErrorAlert.scss";
interface ErrorAlertProps {
  className?: string;
  children: React.ReactElement;
}
const ErrorAlert = ({ className, children }: ErrorAlertProps) => {
  return (
    <Box className={`error-alert-text-with-icon ${className}`}>
      <Image src={redAlert} alt="icon-with-red-alert" />
      <span>{children}</span>
    </Box>
  );
};
export default ErrorAlert;
