"use client";
import { CloseIcon } from "@chakra-ui/icons";
import Image from "next/image";
import { smartChampionIcon } from "public/assets";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./DefinitionTooltip.scss";

type DefinitionTooltipProps = {
  content: ReactNode;
  placement: "top" | "right" | "bottom" | "left";
  children: ReactNode;
  customClass?: string;
  tooltipVisible: boolean;
  setTooltipWrapperVisible: any;
};

const DefinitionTooltip: FC<DefinitionTooltipProps> = ({
  content,
  placement = "right",
  customClass = "",
  children,
  tooltipVisible,
  setTooltipWrapperVisible,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(tooltipVisible);

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event?.target)) {
      setTooltipVisible(false);
      setTooltipWrapperVisible(false);
    }
  };
  const handleScroll = () => {
    return setTooltipVisible(false);
    setTooltipWrapperVisible(false);
  };
  const handleClick = () => {
    setTooltipVisible(!tooltipVisible);
    setTooltipWrapperVisible(!tooltipVisible);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`tooltip-main-container-definition ${customClass}`}
      ref={ref}
    >
      {isTooltipVisible && (
        <div className={`tooltip-content-wrapper ${placement}`}>
          <div className="tooltip-content-text">{content}</div>

          <div className={`tooltip-arrow ${placement}`}></div>
          <div className="tooltip-close-icon" onClick={handleClick}>
            <span>
              <CloseIcon boxSize={5} className="close-icon" />
            </span>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DefinitionTooltip;
