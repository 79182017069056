"use client";
import { CloseIcon } from "@chakra-ui/icons";
import Image from "next/image";
import { smartChampionIcon } from "public/assets";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./FeatureTooltip.scss";

type FeatureTootipProps = {
  content: ReactNode;
  placement: "top" | "right" | "bottom" | "left";
  children: ReactNode;
  customClass?: string;
};

const FeatureTooltip: FC<FeatureTootipProps> = ({
  content,
  placement = "right",
  customClass = "",
  children,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event?.target)) {
      setTooltipVisible(false);
    }
  };
  const handleScroll = () => {
    return setTooltipVisible(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`tooltip-main-container ${customClass}`} ref={ref}>
      {isTooltipVisible && (
        <div className={`tooltip-content-wrapper ${placement}`}>
          <Image src={smartChampionIcon} alt="smart champion" />
          <div className="tooltip-content-text">{content}</div>

          <div className={`tooltip-arrow ${placement}`}></div>
          <div
            className="tooltip-close-icon"
            onClick={() => setTooltipVisible(false)}
          >
            <span>
              <CloseIcon boxSize={5} className="close-icon" />
            </span>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default FeatureTooltip;
