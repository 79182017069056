import React from "react";
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import "./Steppers.scss";
import { rightRotateYellow } from "public/assets";
import Image from "next/image";

type stepItem = {
  title: string;
};

type StepperProps = {
  orientation: "vertical" | "horizontal";
  steps: stepItem[];
  activeIndex: number;
};

export default function Steppers({
  orientation,
  steps,
  activeIndex,
}: StepperProps) {
  const { activeStep } = useSteps({
    index: activeIndex,
    count: steps.length,
  });
  return (
    <div>
      <Stepper
        index={activeStep}
        gap="0"
        orientation={orientation}
        className={
          orientation === "vertical" ? "stepper-vertical" : "stepper-horizontal"
        }
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <Box
              className={
                orientation === "vertical"
                  ? "stepper-stying-vertical"
                  : "stepper-stying-horizontal"
              }
            >
              <StepIndicator
                className={
                  orientation === "vertical"
                    ? "step-indicator-vertical"
                    : "step-indicator-horizontal"
                }
              >
                <StepStatus
                  complete={<StepIcon className="stepper-icon" />}
                  active={<Image src={rightRotateYellow} alt="active-stepper" />}
                />
              </StepIndicator>

              <Box
                flexShrink="0"
                className={
                  orientation === "vertical"
                    ? "step-title-vertical"
                    : "step-title-horizontal"
                }
              >
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Box>
            {/* </Box> */}
            <StepSeparator
              className={
                orientation === "vertical"
                  ? "step-seprator-vertical"
                  : "step-seprator"
              }
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
