import {
  ArrowForwardIcon,
  Box,
  Flex,
  IconButton,
  Link,
  StyleProps,
  Text,
  color,
} from "@/components/ChakraUiManager";
import { iconOutlineSurvey, rightArrow } from "../../../../public/assets";
import Image from "next/image";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import "./ResourceLink.scss";
type ResourceLinkprops = {
  imgProp?: StaticImport | string;
  name: string;
  href: string;
  style?: StyleProps;
  customClass?: string;
};

function ResourceLink({
  name,
  href = "",
  imgProp,
  customClass = "",
}: ResourceLinkprops) {
  return (
    <Flex className={customClass} py="1rem" justify="space-between">
      <Flex alignItems="center">
        {imgProp ? (
          <Box mr="0.8rem">
            <Image src={iconOutlineSurvey} alt={"survey"} />
          </Box>
        ) : (
          ""
        )}
        <Text>{name}</Text>
      </Flex>
      <Link href={href} className="arrow-continer" aria-label={name}>
        <IconButton
          border={0}
          isRound={true}
          variant="outline"
          aria-label="Done"
          fontSize={"var(--chakra-fontSizes-4xl)"}
          className="hover-link"
          icon={<ArrowForwardIcon />}
        />
      </Link>
    </Flex>
  );
}

export default ResourceLink;
