"use client";
import dynamic from "next/dynamic";
import React, { useState, FC, ReactNode } from "react";
import "./GuidedTooltip.scss";
import { Button } from "@chakra-ui/react";
import { blackChewDownIcon } from "public/assets";
import Image from "next/image";
import { CloseIcon } from "@chakra-ui/icons";

import { Progress } from "@chakra-ui/react";

const Joyride = dynamic(() => import("react-joyride"), { ssr: false });

type TooltipProps = {
  continuous: any;
  index: number;
  step: any;
  backProps: any;
  closeProps: any;
  primaryProps: any;
  tooltipProps: any;
  size: number;
};

type GuidedTooltipProps = {
  children: ReactNode;
  tooltipSteps: {
    content: string;
    title: string;
    placement: string;
    target: string;
  }[];
};

const Tooltip: FC<TooltipProps> = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => (
  <div className="container">
    <div {...tooltipProps} className="guided-tooltip-container">
      {step.title && <div className="title">{step.title}</div>}
      <div>{step.content}</div>
      <div {...closeProps} className="close-icon">
        <span>
          <CloseIcon boxSize={5} color="#ffff" />
        </span>
      </div>
      <div className="button-wrapper">
        {index > 0 && (
          <Button {...backProps} className="back-button">
            <div id="back">Button</div>
          </Button>
        )}
        {continuous && (
          <Button {...primaryProps} className="next-button">
            <div id="next">
              Button <Image src={blackChewDownIcon} alt="chev-right" />
            </div>
          </Button>
        )}
      </div>
    </div>
    <Progress
      value={index}
      max={size}
      colorScheme="orange"
      color="gray"
      stroke="#454D56"
      className="progress-bar"
    />
  </div>
);

const GuidedTooltip: FC<GuidedTooltipProps> = ({ children, tooltipSteps }) => {
  const [{ run, steps }, setState] = useState<any>({
    run: true,
    steps: tooltipSteps,
  });

  return (
    <>
      <Joyride
        continuous
        run={run}
        steps={steps}
        scrollToFirstStep
        showSkipButton
        showProgress
        tooltipComponent={Tooltip}
        styles={{
          options: {
            arrowColor: "#26292E",
          },
        }}
      />
      {children}
    </>
  );
};

export default GuidedTooltip;
