"use client";
import { Box, Text, UseToastOptions } from "@chakra-ui/react";
import Image from "next/image";
import {
  CheckCircleIcon,
  CloseIcon,
  InfoIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import cx from "classnames";

import css from "./Toast.module.scss";

interface Props {
  description: string;
  status: "info" | "success" | "error" | "message" | "progress" | "warning";
  onClose: () => void;
}

const Toast = (props: Props) => {
  const { status = "success", description, onClose } = props;

  const renderIcon = () => {
    switch (status) {
      case "success":
        return <CheckCircleIcon />;
      case "info":
        return <InfoIcon />;
      case "error":
        return <WarningTwoIcon />;
      case "warning":
        return <WarningTwoIcon />;
      case "message":
        return <InfoIcon />;
      case "progress":
        return null;
    }
  };

  return (
    <Box className={cx(css["toast-container"], css[`${status}`])}>
      <Box className={cx(css.statusIcon, css[`${status}`])}>{renderIcon()}</Box>
      <Text className={css.message}>{description}</Text>
      <CloseIcon onClick={onClose} className={css["close-icon"]} />
    </Box>
  );
};

export default Toast;
