import React, { useEffect, useState } from "react";
import { getPlayerUrl } from "@/client-api-manager/alm-api";
import "./EmbeddablePlayer.scss";

interface EmbeddablePlayerProps {
  courseId: string;
  type: "course" | "certification" | "learningProgram" | "jobAid";
  moduleId: string;
}

const EmbeddablePlayer: React.FC<EmbeddablePlayerProps> = ({
  courseId,
  type,
  moduleId,
}) => {
  const [embeddableUrl, setEmbeddableUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      // Use getPlayerUrl to construct the URL
      const url = await getPlayerUrl(type, courseId, moduleId);
      setEmbeddableUrl(url);
    };

    fetchUrl();
  }, [courseId, type]);

  if (!embeddableUrl) {
    return <div>Loading player...</div>; // You can replace this with a spinner or any loading indicator
  }

  return (
    <div className="embeddable-player-container">
      <iframe
        src={embeddableUrl}
        className="iframe"
        allowFullScreen
        title="Adobe Learning Manager Player"
      />
    </div>
  );
};

export default EmbeddablePlayer;
