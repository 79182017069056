"use client";

import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Portal,
  Text,
} from "@/components/ChakraUiManager";
import { useState } from "react";
import "./FeedbackModal.scss";

import { FooterWidgetIframe } from "@/components/common";
import { CloseButton, HStack, Spacer, VStack } from "@chakra-ui/react";

const FeedbackModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Portal>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        className="button-feedback"
        hidden={isOpen}
      >
        <Text className="text">Feedback</Text>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        scrollBehavior={"outside"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent className="modal-content">
          <VStack className="grid-modal-content">
            <HStack>
              <Spacer />
              <CloseButton onClick={() => setIsOpen(!isOpen)} />
            </HStack>
            <FooterWidgetIframe />
          </VStack>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default FeedbackModal;
