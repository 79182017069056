"use client";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import "./ToolTip.scss";
import Image from "next/image";
import { kebabIcon } from "public/assets";
import {
  Popover,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import cx from "classnames";

type TooltipProps = {
  content: string;
  placement: "top" | "right" | "bottom" | "left";
  IconDefault?: boolean;
  IconDeactivated?: boolean;
  onClose?: any;
  isViewSvgIcon?: boolean;
  isKebabIcon?: boolean;
  defaultOpen?: boolean;
};

export default function ToolTip({
  content,
  placement = "right",
  isViewSvgIcon = true,
  isKebabIcon,
  IconDefault,
  IconDeactivated,
  defaultOpen,
  onClose: propsOnClose,
}: TooltipProps) {
  return (
    <Popover
      onClose={() => {
        propsOnClose?.();
      }}
      defaultIsOpen={defaultOpen}
      placement={placement}
      arrowSize={14}
      modifiers={[
        {
          name: "flip",
          options: {
            fallbackPlacements: ["left", "bottom"],
          },
        },
      ]}
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            {isViewSvgIcon ? (
              <div className="tooltip-icon">
                <span>
                  <InfoOutlineIcon
                    className={cx({
                      "icon-default": Boolean(IconDefault),
                      "icon-deactivated": Boolean(IconDeactivated),
                      "blue-info-icon": isOpen,
                    })}
                    viewBox="-5 -5 30 30"
                  />
                </span>
              </div>
            ) : isKebabIcon ? (
              <div className="icon-kebab">
                <Image src={kebabIcon} alt="kebab icon" />
              </div>
            ) : (
              <div className="circle"></div>
            )}
          </PopoverTrigger>
          <PopoverContent className="tooltip-content">
            <PopoverArrow />
            <PopoverCloseButton
              width="24px"
              height="24px"
              fontSize="md"
              top={4}
              right={4}
            />
            <PopoverBody padding={0} paddingRight={6}>
              <div
                className="content-text"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}
