"use client";
import React, { useState } from "react";
import { Progress } from "@chakra-ui/react";
import "./ProgressBar.scss";

type ProgressBarProp = {
  value: number;
  focus?: boolean;
  disabled?: boolean;
  hoverEffect?: boolean;
};

export default function ProgressBar({
  value,
  focus,
  disabled,
  hoverEffect = true,
}: ProgressBarProp) {
  const containerClassNames = [
    "progress-bar-background",
    focus ? "progress-bar-background-focus" : "",
    disabled ? "progress-bar-background-disabled" : "",
    hoverEffect ? "progress-bar-hover" : "",
  ].join(" ");
  let displayValue = `1/${value}`;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <Progress
        value={value}
        colorScheme="orange"
        className={containerClassNames}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {hoverEffect && isHovered && (
        <p
          className="hover-text"
          style={{ position: "absolute", left: `${value}%` }}
        >
          {displayValue}
        </p>
      )}
    </div>
  );
}
