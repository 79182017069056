"use client";
import { useSortable } from "@dnd-kit/sortable";
import "./SortableItem.scss";
import { CSS } from "@dnd-kit/utilities";
import { draggableIcon } from "../../../../../public/assets";
import Image from "next/image";
import { Text } from "@chakra-ui/react";

export function SortableItem({ value }: any) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value });
  const style = { transform: CSS.Transform.toString(transform), transition };
  return (
    <div
      className="sortable-item"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <Image src={draggableIcon} alt="draggable icon" />
      <Text>{value}</Text>
    </div>
  );
}
