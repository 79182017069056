"use client";
import { Button, HStack } from "@/components/ChakraUiManager";
import "./SplitButton.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { IFX_ACCT_PRODUCT_TYPE, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { useContext } from "react";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

export type SplitBtnInputValType = boolean | undefined;

interface SplitButtonProps {
  name?: string;
  leftBtnName: string;
  rightBtnName: string;
  value: SplitBtnInputValType;
  isLeftBtnDisabled?: boolean;
  isRightBtnDisabled?: boolean;
  ignoreConfigFlag?: boolean;
  onSelect: (clikedBtn: NonNullable<SplitBtnInputValType>) => void;
  onBlur?: (event: React.FocusEvent) => void;
}

const SplitButton = (props: SplitButtonProps) => {
  const {
    name,
    value,
    leftBtnName,
    rightBtnName,
    isLeftBtnDisabled = false,
    isRightBtnDisabled = false,
    ignoreConfigFlag = false,
    onSelect,
    onBlur,
  } = props;

  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const leftButtonVariant =
    value === null ? "outline" : (value ? "solid" : "outline");

  const rightButtonVariant =
    value === null ? "outline" : (value === false ? "solid" : "outline");

  return (
    <>
      <HStack spacing={0} onBlur={onBlur}>
        <Button
          name={name}
          className={`split-button-left ${value ? "activeBtn" : ""}`}
          variant={leftButtonVariant}
          onClick={() => onSelect(true)}
          color={value ? "white" : "black"}
          isDisabled={(envIsDisabled && isConsolePage) || isLeftBtnDisabled || checkEntitlement}
        >
          {leftBtnName}
        </Button>
        <Button
          name={name}
          className={`split-button-right ${value === false ? "activeBtn" : ""}`}
          variant={rightButtonVariant}
          onClick={() => onSelect(false)}
          color={value === false ? "white" : "black"}
          isDisabled={(envIsDisabled && isConsolePage) || isRightBtnDisabled || checkEntitlement}
        >
          {rightBtnName}
        </Button>
      </HStack>
    </>
  );
};

export default SplitButton;
