"use client";

import { useUserAuthenticated } from "@/app/providers";
import { getAppConfig } from "@/store";
import { Timeout } from "@finxact/finxact-shared-ui";
import { useSelector } from "react-redux";

const TimeoutHandler = () => {
  const userAuthenticated = useUserAuthenticated();
  const appConfig = useSelector(getAppConfig);

  return (
    <>
      {userAuthenticated &&
      appConfig?.sesnTimeoutMs &&
      appConfig.sesnTimeoutWarnMs ? (
        <Timeout
          sesnTimeoutMs={appConfig.sesnTimeoutMs}
          sesnTimeoutWarnMs={appConfig.sesnTimeoutWarnMs}
        />
      ) : null}
    </>
  );
};

export default TimeoutHandler;
