"use client";
interface ApiClientConfig {
  baseUrl: string;
  defaultOpts?: any;
}

export class ApiClient {
  baseUrl;
  defaultOpts;
  constructor(opts: ApiClientConfig) {
    const { baseUrl, defaultOpts } = opts;
    if (baseUrl) {
      this.baseUrl = baseUrl;
    }
    this.defaultOpts = {
      ...defaultOpts,
    };
  }

  get(resource = "", opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, { ...this.defaultOpts, method: "GET", ...opts }).then(
      _throwError,
    );
  }

  put(resource: string, opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, { ...this.defaultOpts, method: "PUT", ...opts }).then(
      _throwError,
    );
  }

  post(resource: string, opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, {
      ...this.defaultOpts,
      method: "POST",
      ...opts,
      headers: {
        ...this.defaultOpts.headers,
        "content-type": "application/json",
      },
    }).then(_throwError);
  }

  patch(resource: string, opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, {
      ...this.defaultOpts,
      ...opts,
      method: "PATCH",
      headers: {
        ...this.defaultOpts.headers,
        "content-type": "application/json",
      },
    }).then(_throwError);
  }

  options(resource: string, opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, {
      ...this.defaultOpts,
      method: "OPTIONS",
      ...opts,
      headers: {
        ...this.defaultOpts.headers,
        "content-type": "application/json",
      },
    })
      .then(_throwError)
      .catch((err) => console.error(err));
  }

  delete(resource: string, opts?: any) {
    const url = this.baseUrl ? `${this.baseUrl}${resource}` : resource;
    return fetch(url, { ...this.defaultOpts, method: "DELETE", ...opts }).then(
      _throwError,
    );
  }
}

const _throwError = async (res: Response) => {
  if (!res.ok) {
    try {
      const response = await res.json();
      throw response;
    } catch (e: any) {
      throw e?.stack?.includes("SyntaxError") ? res.statusText : e;
    }
  }
  return res?.json();
};
