"use client";

import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { blackChewDownIcon } from "public/assets";
import { CurrentRefinements, RefinementList } from "react-instantsearch";
import { CardContainer } from "..";
import { useEffect, useRef, useState } from "react";
import "./AlgoliaSearchFilter.scss";

interface AlgoliaSearchFilterProp {
  ignoreLabel?: boolean;
  refinementAttribute: string;
  refinementOperator?: "or" | "and" | undefined;
  refinementSortBy?: string[] | Function | undefined;
  customClass?: string;
}

export default function AlgoliaSearchFilter(props: AlgoliaSearchFilterProp) {
  const {
    ignoreLabel = false,
    refinementAttribute,
    refinementOperator,
    refinementSortBy,
    customClass,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  function toggleShowFilter() {
    setShowFilter(!showFilter);
  }

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showFilter && ref.current && !ref.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showFilter]);

  return (
    <Flex
      className={`algolia-search-filter-container ${customClass ?? ""}`}
      justifyContent={"space-between"}
    >
      <CurrentRefinements
        transformItems={(items) =>
          items.map((item) => {
            return {
              ...item,
              label: ignoreLabel ? "" : item.label,
            };
          })
        }
      />
      <Box position={"relative"}>
        <Button
          onClick={toggleShowFilter}
          className={`filter-button ${showFilter ? "filter-btn-selected" : ""}`}
        >
          <div className="filter-by">Filter By</div>
          <Image
            className={`${showFilter ? "transform-180deg" : ""}`}
            src={blackChewDownIcon.src}
            alt="arrow-down"
          />
        </Button>
        <Box ref={ref}>
          <CardContainer
            customClass={`filter-items algolia-search-filter-dropdown ${showFilter ? "" : "display-none"}`}
          >
            <RefinementList
              operator={refinementOperator}
              attribute={refinementAttribute}
              sortBy={refinementSortBy as any}
            />
          </CardContainer>
        </Box>
      </Box>
    </Flex>
  );
}
