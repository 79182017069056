"use client";

import { ReactElement } from "react";
import { Button, Link } from "@chakra-ui/react";
import "./IconLinkButton.scss";

type MyComponentProps = {
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  text?: string;
  size?: string;
  id?: string;
  link?: string;
};

const IconLinkButton = ({ rightIcon, leftIcon, text, size = "xl", id, link }: MyComponentProps) => (
  <span className="icon-link-button">
    <Link {...(link && { href: link })}>
      <Button size={size} variant="link" id={id} {...(rightIcon && {rightIcon})} {...(leftIcon && {leftIcon}) } >
        {text}
      </Button>
    </Link>
  </span>
);

export default IconLinkButton;
