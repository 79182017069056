import {
  HStack,
  Button,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import variables from "../../../styles/_export.module.scss";
import { sideMenuMultiTieredBackIcon } from "public/assets";
import "./MultiTieredSideMenuBackHeader.scss";

// Menu Header
interface MultiTieredSideMenuBackHeaderProps {
  title: string;
  onClick: () => void;
}

const MultiTieredSideMenuBackHeader = ({
  title,
  onClick,
}: MultiTieredSideMenuBackHeaderProps) => {
  return (
    <HStack w="full">
      <Button
        leftIcon={
          <Image
            src={sideMenuMultiTieredBackIcon.src}
            alt="side menu back icon"
          />
        }
        aria-label="button back side menu"
        onClick={onClick}
        backgroundColor="transparent"
      >
        <Text className="back-title">{title}</Text>
      </Button>
    </HStack>
  );
};

export default MultiTieredSideMenuBackHeader;
