import { Box, Text } from "@/components/ChakraUiManager";
import "./DashboardFooter.scss";

type ProductTitleProp = {
  title: string;
  subTitle?: string;
};

const DashboardFooter = (props: ProductTitleProp) => {
  const { title, subTitle } = props;
  return (
    <Box className={`top-dashboard-footer`}>
      <Text className="footer-text">
        Copyright © 2024 Finxact a Fiserv Company. All rights reserved.
      </Text>
    </Box>
  );
};
export default DashboardFooter;
