import { Box, Text } from "@/components/ChakraUiManager";
import "./AppFormLabel.scss";
import { ToolTip } from "../..";

export type AppFormLabelProps = {
  labelName?: string;
  isRequired?: boolean;
  tooltipDesc?: string;
  labelFor?: string;
};

export default function AppFormLabel(props: AppFormLabelProps) {
  const { labelName, isRequired, tooltipDesc, labelFor } = props;
  return (
    <Box className="form-label-container">
      <Text
        className="form-label"
        as="label"
        htmlFor={labelFor}
        onClick={(event: any) => {
          event.stopPropagation();
          event.nativeEvent.preventDefault();
        }}
      >
        {labelName}
        {isRequired ? <span className="form-required">*</span> : ""}
        {tooltipDesc ? (
          <ToolTip
            content={tooltipDesc}
            placement={"right"}
            IconDefault={true}
          />
        ) : (
          ""
        )}
      </Text>
    </Box>
  );
}
