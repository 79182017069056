"use client";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  draggableIcon,
  accordionDeleteIcon,
} from "../../../../../public/assets";
import "./SortableElement.scss";
import Image from "next/image";

export function SortableElement({ value }: any) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value.id });
  const style = {
    transform: CSS.Transform.toString(transform),
  };
  return (
    <div className="sortable-element" style={style}>
      <div className="icon-container">
        {value.deleteHandler && value.showDeleteIcon && (
          <Image
            className="delete-icon"
            src={accordionDeleteIcon}
            alt="delete icon"
            onClick={value.deleteHandler}
          />
        )}
        <Image
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          className="sort-icon"
          src={draggableIcon}
          alt="draggable icon"
        />
      </div>
      {value.content}
    </div>
  );
}
