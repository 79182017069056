import { Heading, Text } from "@/components/ChakraUiManager";
import "./StaticText.scss";
interface StaticTextProps {
  textValue?: string | number;
  className?: string;
  heading?: boolean;
  headingName?: string;
  pt?: string;
  pb?: string;
}
const StaticText = (props: StaticTextProps) => {
  const { textValue, className, heading, headingName, pt, pb } = props;
  if (heading) {
    return (
      <div className="static-text">
        <Heading as="h2" size="xl" paddingTop={pt} paddingBottom={pb}>
          {headingName}
        </Heading>
      </div>
    );
  }
  return <div className={`static-text ${className}`}>{textValue}</div>;
};

export default StaticText;
