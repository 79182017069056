"use client";
import { Box, Checkbox, Grid, GridItem, Text } from "@chakra-ui/react";
import {
  AppFormLabel,
  CardContainer,
  DatePicker,
  ErrorAlert,
  InputText,
  TimeField,
} from "@/components/common";
import { FinancialCalenderHolidayItem } from "@/components/institutional-configuration/financial-calender/holiday-configuration/FinancialCalenderHolidayConfigurationValidation";
import Image from "next/image";
import { deleteHolidayIcon } from "public/assets";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import React, { useContext } from "react";
const HolidayCard = (props: FinancialCalenderHolidayItem) => {
  const {
    title,
    isAllDay,
    openTm,
    closeTm,
    date,
    onUpdate,
    onDelete,
    errors,
    touched,
    index,
  } = props;

  const error = errors && errors[index];
  const _touched = touched && touched[index];
  const { tooltipFlyoutDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );

  return (
    <CardContainer>
      <Grid display="flex" gap={6}>
        <GridItem>
          <Text fontSize="large" fontWeight="bold">
            {title}
          </Text>
        </GridItem>
        <GridItem
          display="flex"
          alignItems="center"
          marginTop={-1}
          onClick={onDelete}
          style={{
            cursor: "pointer",
          }}
        >
          <Image
            src={deleteHolidayIcon}
            alt="Delete"
            width={24}
            height={24}
            color="red"
          />
        </GridItem>
      </Grid>
      <Box
        style={{
          marginTop: "32px",
          width: "350px",
        }}
      >
        <AppFormLabel
          labelName="Holiday Date"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("holidayDtm")
              ?.tooltip_text_main
          }
        />
        <DatePicker
          value={date}
          onUpdate={(date) => {
            const _item = { ...props };
            _item.date = date;
            onUpdate && onUpdate(_item);
          }}
          placeholder="Select"
          ariaLabel="Choose the holiday date"
        />
        {error && _touched && error.date && (
          <ErrorAlert>
            <span>{error.date}</span>
          </ErrorAlert>
        )}
      </Box>

      <Grid display="flex" gap={30}>
        <GridItem w="164px" marginTop="32px">
          <AppFormLabel
            labelName="openTm"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("holidayDtmFrm")
                ?.tooltip_text_main
            }
          />
          <TimeField
            value={openTm ?? ""}
            onChange={(value) => {
              const _item = { ...props };
              _item.openTm = value;
              onUpdate && onUpdate(_item);
            }}
            ariaLabel={"Holiday start from"}
            disabled={isAllDay}
            placeholder="18:30:00"
          />
          {error && _touched && error.openTm && (
            <ErrorAlert>
              <span>{error.openTm}</span>
            </ErrorAlert>
          )}
        </GridItem>

        <GridItem w="164px" marginTop="32px">
          <AppFormLabel
            labelName="To"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("holidayDtmTo")
                ?.tooltip_text_main
            }
          />
          <TimeField
            value={closeTm ?? ""}
            onChange={(value) => {
              const _item = { ...props };
              _item.closeTm = value;
              onUpdate && onUpdate(_item);
            }}
            ariaLabel={"Holiday ends at"}
            disabled={isAllDay}
            placeholder="18:30:00"
          />
          {error && _touched && error.closeTm && (
            <ErrorAlert>
              <span>{error.closeTm}</span>
            </ErrorAlert>
          )}
        </GridItem>

        <GridItem w="164px" marginTop="32px">
          <AppFormLabel labelName="All day" />
          <Checkbox
            marginTop="13px"
            isChecked={isAllDay}
            aria-label={"Is this holiday available for all day"}
            onChange={(event) => {
              const _item = { ...props };
              _item.isAllDay = event.target.checked;
              onUpdate && onUpdate(_item);
            }}
          />
        </GridItem>
      </Grid>
    </CardContainer>
  );
};

export default HolidayCard;
