"use client";
import React from "react";
import {
  Box,
  HStack,
  VStack,
  Tag,
  Button,
  chakra,
} from "../../ChakraUiManager";
import AvatarIcon from "../avatar-icon/AvatarIcon";
import "./UserWidget.scss";
import { ChevronRightIcon } from "@chakra-ui/icons";

type UserWidgetProps = {
  name: String;
  badges: number;
  courses: number;
  onButtonClick: () => void;
};

function UserWidget({ name, badges, courses, onButtonClick }: UserWidgetProps) {
  const handleButtonClick = () => {
    onButtonClick();
  };
  return (
    <Box display="flex" justifyContent="space-between">
      <HStack spacing="12">
        <AvatarIcon type="circle" size="2xl" />
        <VStack align="start">
          <h2 className="profile-tag">{name}</h2>

          <HStack>
            <p className="tag-style">{badges} BADGES</p>
            <p className="tag-style">{courses} COURSES</p>
          </HStack>
        </VStack>
      </HStack>
      <Button onClick={handleButtonClick} className="share-button">
        <h1 className="share-user-widget">Share</h1>
        <ChevronRightIcon className="right-icon-color" />
      </Button>
    </Box>
  );
}

export default UserWidget;
