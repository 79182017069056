"use client";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  StyleProps,
  forwardRef,
  useDisclosure,
} from "@/components/ChakraUiManager";
import { RefObject, ButtonHTMLAttributes, useImperativeHandle } from "react";
import Image from "next/image";
import "./AppModal.scss";
import { StaticImport } from "next/dist/shared/lib/get-img-props";

type AppModalBtnGroupProp = {
  name: string;
  btnClassName: string;
  style?: StyleProps;
  disabled?: boolean;
  onBtnClick?: any;
  enableCloseBtn?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  form?: string;
};

export type AppModalProp = {
  modalTitle: string;
  description?: string;
  imageProp?: StaticImport;
  primaryBtnProp?: AppModalBtnGroupProp;
  secondaryBtnProp?: AppModalBtnGroupProp;
  linkBtnProp?: AppModalBtnGroupProp;
  splitImage?: boolean;
  customClass?: string;
  children?: React.ReactNode;
  primaryBtnSelect?: any;
  secondaryBtnSelect?: any;
  primaryBtnDisabled?: boolean;
  containerRef?: RefObject<HTMLElement | null> | undefined;
  isCentered?: boolean;
  showCloseIcon?: boolean;
  scrollBehavior?: "inside" | "outside";
};

const AppModal = forwardRef((props: AppModalProp, ref: any) => {
  const {
    modalTitle,
    description,
    primaryBtnProp,
    secondaryBtnProp,
    linkBtnProp,
    imageProp,
    splitImage,
    customClass,
    children,
    primaryBtnSelect,
    secondaryBtnSelect,
    primaryBtnDisabled,
    containerRef = undefined,
    isCentered = false,
    showCloseIcon = true,
    scrollBehavior = "outside",
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    openModal() {
      onOpen();
    },
    closeModal() {
      onClose();
    },
  }));

  return (
    <Portal containerRef={containerRef}>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          secondaryBtnSelect?.();
        }}
        scrollBehavior={scrollBehavior}
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent className={`app-modal-content ${customClass || ""}`}>
          <Box
            className={`app-modal-body-container ${
              splitImage ? "app-modal-split-image" : ""
            }`}
          >
            {imageProp ? (
              <Box>
                <Image src={imageProp} alt="image" />{" "}
              </Box>
            ) : (
              ""
            )}

            <Box className="app-modal-body ">
              {modalTitle && (
                <ModalHeader className="app-modal-title">
                  {modalTitle}
                </ModalHeader>
              )}
              {showCloseIcon && <ModalCloseButton />}
              <ModalBody>{children || description}</ModalBody>
              <ModalFooter>
                {linkBtnProp?.name ? (
                  <Button
                    className={linkBtnProp.btnClassName}
                    isDisabled={linkBtnProp.disabled || false}
                    onClick={
                      linkBtnProp.enableCloseBtn
                        ? onClose
                        : linkBtnProp.onBtnClick
                    }
                    {...linkBtnProp.style}
                  >
                    {linkBtnProp.name}
                  </Button>
                ) : (
                  ""
                )}
                {secondaryBtnProp?.name ? (
                  <Button
                    className={secondaryBtnProp.btnClassName}
                    type={
                      secondaryBtnProp.type ? secondaryBtnProp.type : "button"
                    }
                    isDisabled={secondaryBtnProp.disabled || false}
                    onClick={
                      secondaryBtnProp.enableCloseBtn
                        ? () => {
                            onClose();
                            secondaryBtnSelect?.();
                          }
                        : () => {
                            onClose();
                            secondaryBtnSelect?.();
                          }
                    }
                    {...secondaryBtnProp.style}
                  >
                    {secondaryBtnProp.name}
                  </Button>
                ) : (
                  ""
                )}
                {primaryBtnProp?.name ? (
                  <Button
                    className={primaryBtnProp.btnClassName}
                    isDisabled={primaryBtnDisabled || false}
                    onClick={() => {
                      // If button not submittin a form
                      if (!primaryBtnProp.form) {
                        primaryBtnProp.enableCloseBtn
                          ? onClose()
                          : primaryBtnSelect?.();
                      }
                    }}
                    {...primaryBtnProp.style}
                    type={primaryBtnProp.form ? "submit" : "button"}
                    form={primaryBtnProp.form}
                  >
                    {primaryBtnProp.name}
                  </Button>
                ) : (
                  ""
                )}
              </ModalFooter>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </Portal>
  );
});

export default AppModal;
