import {
  Box,
  Button,
  ChevronRightIcon,
  Flex,
  Heading,
  SimpleGrid,
  Spacer,
} from "@/components/ChakraUiManager";
import "./ContentCatalogue.scss";
import { CardContainer } from "..";
import styleVars from "../../../styles/_export.module.scss";

type LinkProps = {
  name: string;
  [key: string]: any;
};

type CatalogueItemProp = {
  title: string;
  catalogueItemList: LinkProps[];
  itemFunc?: any;
  [key: string]: any;
};

export type ContentCatalogueProp = {
  title: string;
  headingName: string;
  headingLinkProp?: LinkProps;
  customClass?: string;
  catalogueList: CatalogueItemProp[];
  linkFunc?: any;
  columnPerRow?: number;
};

function ContentCatalogue(props: ContentCatalogueProp) {
  const { appDefaultLinkColor } = styleVars;
  const {
    title,
    headingName,
    headingLinkProp,
    customClass,
    catalogueList,
    linkFunc,
    columnPerRow = 3,
  } = props;

  function callPropFunc(prop: any) {
    if (prop) {
      linkFunc({ ...prop });
    }
  }

  function showRemoveBorderClass(itemIndex: number): boolean {
    if (itemIndex % columnPerRow === 0) {
      return true;
    }
    return false;
  }

  return (
    <Box className={`content-catalogue-container ${customClass}`}>
      <CardContainer>
        <Heading px="2rem" as="h3">
          {title}
        </Heading>
        <Flex px="2rem" className="content-catalogue-header">
          <Box w={"60%"}>{headingName}</Box>
          <Spacer />
          {headingLinkProp?.name ? (
            <Button
              onClick={() => callPropFunc(props)}
              className="app-btn-link"
            >
              {headingLinkProp?.name}
            </Button>
          ) : (
            ""
          )}
        </Flex>
        <SimpleGrid
          px="2rem"
          mt="2rem"
          spacingX="2rem"
          className="content-catalogue-body"
          columns={{ sm: 1, md: columnPerRow }}
        >
          {catalogueList?.length
            ? catalogueList.map(
                ({ catalogueItemList, title }, catalogueIndex) => (
                  <Box
                    key={`catalogueIndex${catalogueIndex}`}
                    className={`content-catalogue ${
                      showRemoveBorderClass(++catalogueIndex)
                        ? "remove-border"
                        : ""
                    }`}
                  >
                    <Heading as="h3">{title}</Heading>
                    <Box>
                      {catalogueItemList?.length
                        ? catalogueItemList.map(
                            (item: LinkProps, catalogueItemIndex) => (
                              <Button
                                key={`catalogueItemIndex${catalogueItemIndex}`}
                                my="2rem"
                                colorScheme={appDefaultLinkColor}
                                rightIcon={
                                  <ChevronRightIcon boxSize={"1.8rem"} />
                                }
                                className="app-btn-link"
                                onClick={() => callPropFunc(item)}
                              >
                                {item.name}
                              </Button>
                            )
                          )
                        : ""}
                    </Box>
                  </Box>
                )
              )
            : ""}
        </SimpleGrid>
      </CardContainer>
    </Box>
  );
}

export default ContentCatalogue;
