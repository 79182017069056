"use client";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import "./CarouselButtonGroup.scss";
const CarouselButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;
  return (
    <div className="carousel-button-group">
      <Button
        aria-label="carousel-arrow-left"
        leftIcon={<ChevronLeftIcon />}
        className={`carousel-arrow-left ${currentSlide === 0 ? "hidden" : ""}`}
        onClick={() => previous()}
      />
      <Button
        aria-label="carousel-arrow-right"
        rightIcon={<ChevronRightIcon />}
        className={`carousel-arrow-right ${currentSlide === totalItems - slidesToShow ? "hidden" : ""}`}
        onClick={() => next()}
      />
    </div>
  );
};

export default CarouselButtonGroup;
