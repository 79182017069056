"use client";

import {
  ArrowForwardIcon,
  Badge,
  Box,
  Divider,
  StyleProps,
  Text,
} from "../../ChakraUiManager";
import "./LearningInfoCard.scss";
import Link from "next/link";
import Image from "next/image";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import { CardContainer } from "..";

type InfoCardEditorInfo = {
  name: string;
  date?: string;
};

export type LearningInfoCardProps = {
  title: string;
  description: string;
  headerStyle?: StyleProps;
  tagList?: string[];
  editorInfo?: InfoCardEditorInfo;
  mediaInfo?: StaticImport | string;
  arrowLinkPath?: string;
  customClass?: string;
  styleProps?: StyleProps;
  disabled?: boolean;
  splitImage?: boolean;
};

function LearningInfoCard(props: LearningInfoCardProps) {
  const {
    title,
    description,
    headerStyle,
    tagList,
    editorInfo,
    mediaInfo,
    arrowLinkPath,
    customClass,
    styleProps,
    disabled = false,
    splitImage = false,
  } = props;

  return (
    <CardContainer
      customClass={`learning-info-card-container 
      ${customClass || ""}
      ${splitImage ? "learning-info-card-media-direction" : ""}
      ${disabled ? "disabled-info-card" : ""}
      `}
      styleProps={styleProps}
    >
      {mediaInfo ? (
        <div className="learning-info-card-media-container">
          <Image width="170" height="170" src={mediaInfo} alt="finxact" />
        </div>
      ) : (
        ""
      )}

      <div className={`learning-info-card-content`}>
        <Box>
          <Text className="learning-info-card-title">{title}</Text>
          <Box mt="1.2rem">
            {tagList?.map((tagName, tagIndex) => (
              <Badge key={`tagIndex${tagIndex}`} className="app-badge">
                {tagName}
              </Badge>
            ))}
          </Box>
          {editorInfo?.name ? (
            <Box mt="1.2rem" display="flex">
              <Box>By:</Box>
              <Box ml="0.4rem" mr="0.8rem">
                {editorInfo?.name}
              </Box>
              <Divider
                className="learning-info-card-divider"
                orientation="vertical"
              />
              <Box ml="0.8rem">{editorInfo?.date || ""}</Box>
            </Box>
          ) : (
            ""
          )}

          <Text my="1rem">{description}</Text>
        </Box>
        {arrowLinkPath ? (
          <Box className="learning-info-card-arrow">
            <Box className="learning-info-card-arrow-container">
              <Link
                className="learning-info-card-arrow-box"
                href={arrowLinkPath}
              >
                <ArrowForwardIcon width="3.2rem" height="2rem" />
              </Link>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </div>
    </CardContainer>
  );
}

export default LearningInfoCard;
