"use client";

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Grid,
  GridItem,
  Box,
  VStack,
  useMediaQuery,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Heading,
  Image,
  Divider,
  Portal,
} from "@chakra-ui/react";
import "./MultiTieredSideMenu.scss";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { MultiTieredSideMenuDataModel } from "./MultiTieredSideMenuDataModel";
import variables from "../../../styles/_export.module.scss";
import MultiTieredSideMenuToggleHeader from "./MultiTieredSideMenuToggleHeader/MultiTieredSideMenuToggleHeader";
import MultiTieredSideMenuHeader from "./MultiTieredSideMenuHeader/MultiTieredSideMenuHeader";
import MultiTieredSideMenuItem from "./MultiTieredSideMenuItem/MultiTieredSideMenuItem";
import MultiTieredSideMenuBackHeader from "./MultiTieredSideMenuBackHeader/MultiTieredSideMenuBackHeader";
import { docCenterSidemenuIconHome } from "public/assets";
import { AccordionExpandMore } from "public/assets";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

export enum MultiTieredSideMenuFormat {
  documentcenter,
  apiLibrary,
  global,
  academy,
}

interface MultiTieredSideMenuProps {
  format: MultiTieredSideMenuFormat;
  selectedElements: MultiTieredSideMenuDataModel[];
  setSelectedElements: Dispatch<SetStateAction<MultiTieredSideMenuDataModel[]>>;
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>;
  showFooter?: boolean;
  envName?: string;
  serviceEndpoint?: string;
  selectedUID: string | null | undefined;
  setSelectedUID: Dispatch<SetStateAction<string | null | undefined>>;
}

const MultiTieredSideMenu = ({
  selectedElements,
  setSelectedElements,
  isSideMenuOpen,
  setIsSideMenuOpen,
  format,
  showFooter,
  envName,
  serviceEndpoint,
  selectedUID,
  setSelectedUID,
}: MultiTieredSideMenuProps) => {
  const MAX_WIDTH = Number(variables.multiTieredSideMenuMaxWidth);
  const [drawerMaxWidth, setDrawerMaxWidth] = useState<number>(MAX_WIDTH);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isMobileDevice] = useMediaQuery(
    "only screen and ((max-device-width: 640px) or (max-device-width: 768px))"
  );
  const [prevUID, setPrevUID] = useState(selectedUID);
  useEffect(() => {
    setDrawerMaxWidth((selectedElements.length - 1) * MAX_WIDTH);
  }, [selectedElements]);

  const handleTextSelection = (
    item: MultiTieredSideMenuDataModel,
    column: number,
    isUnderAccordion: boolean
  ) => {
    if (item.uid === selectedUID) {
      return;
    }

    let _arrN = [...selectedElements.slice(0, column + 1)];

    if (isUnderAccordion) {
      _arrN[column].childrens.forEach((item) =>
        item.childrens.map((i) => (i.isSelected = false))
      );
    }

    if (item.childrens.length > 0) {
      _arrN[column + 1] = item;
    }
    setSelectedElements(_arrN);

    if (item.childrens.length === 0 && isMobileDevice) {
      setIsSideMenuOpen(false);
    }

    setSelectedUID(item.uid);
    if (item.childrens.length === 0) {
      setPrevUID(item.uid);
    }
  };

  const handleSelection = (
    item: MultiTieredSideMenuDataModel,
    column: number,
    isUnderAccordion: boolean
  ) => {
    // On second click on the espand icon it should close the drawer
    const isColumnOpned = selectedElements.length - 1 > column;
    if (item.isSelected && isDrawerOpen && isColumnOpned) {
      const locSelectedItems = [...selectedElements.slice(0, column + 1)];
      setSelectedElements([...locSelectedItems]);
      if (locSelectedItems.length === 1) {
        setIsDrawerOpen(false);
        setSelectedUID(prevUID);
      }
      return;
    }

    if (item.uid === selectedUID && !isDrawerOpen) {
      setIsDrawerOpen(true);
      return;
    }

    let _arrN = [...selectedElements.slice(0, column + 1)];

    if (isUnderAccordion) {
      _arrN[column].childrens.forEach((item) =>
        item.childrens.map((i) => (i.isSelected = false))
      );
    }

    if (item.childrens.length > 0) {
      _arrN[column + 1] = item;
    }
    setSelectedElements(_arrN);
    if (item.childrens.length > 0 && !isMobileDevice) {
      !isDrawerOpen && setIsDrawerOpen(true);
    } else if (item.childrens.length === 0 && isMobileDevice) {
      setIsSideMenuOpen(false);
    }

    setSelectedUID(item.uid);
    if (item.childrens.length === 0) {
      setPrevUID(item.uid);
    }
  };

  const handleChildren = (
    items: MultiTieredSideMenuDataModel[],
    column: number,
    isUnderAccordion: boolean
  ) => {
    if (!items || items.length <= 0) return null;

    return items.map((item, index) => {
      if (!item) return null;

      switch (item.type) {
        case "header":
          return (
            <GridItem key={index} w="full">
              <MultiTieredSideMenuHeader
                keyName={`menuHeader${index}`}
                data={item}
                isSideMenuOpen={isSideMenuOpen}
              />
            </GridItem>
          );
        case "item":
          return (
            <ProtectedRoute route={item} key={index}>
              <GridItem w="full">
                <MultiTieredSideMenuItem
                  keyName={index}
                  data={item}
                  onSelect={(item) => {
                    handleSelection(item, column, isUnderAccordion);
                  }}
                  onTextSelect={(item) => {
                    handleTextSelection(item, column, isUnderAccordion);
                  }}
                  column={column}
                  isSideMenuOpen={isSideMenuOpen}
                  isFromAPILibrary={
                    format === MultiTieredSideMenuFormat.apiLibrary
                  }
                  serviceEndpoint={serviceEndpoint}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  isLastColumn={selectedElements.length - 1 === column}
                  setParentDocClick={(id) => {
                    setSelectedUID(id);
                    setPrevUID(id);
                    setIsDrawerOpen(false);
                  }}
                />
              </GridItem>
            </ProtectedRoute>
          );
        case "accordion":
          return (
            <Accordion
              key={`accordionMenu${index}`}
              defaultIndex={[0]}
              allowToggle
            >
              <AccordionItem className="accordion-menu-item">
                {({ isExpanded }) => (
                  <>
                    <Heading as="h6">
                      <Divider className="separator" />
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {item.title}
                        </Box>
                        <Image
                          className={`drop-down-icon ${!isExpanded ? "rotate-expanded" : ""}`}
                          src={AccordionExpandMore.src}
                          alt={`${!isExpanded ? "icon collapse" : "icon expand"}`}
                        />
                      </AccordionButton>
                    </Heading>
                    <AccordionPanel>
                      {handleChildren(item.childrens, column, true)}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          );
      }
    });
  };

  const firstColumn = (envName: string) => {
    return (
      <VStack
        className={`
        container-first-column ${selectedElements[1] && isSideMenuOpen && isDrawerOpen ? "selected" : ""} ${showFooter ? "isFooter" : ""}
      `}
      gap={0}

      >
        <MultiTieredSideMenuToggleHeader
          isSideMenuOpen={isSideMenuOpen}
          setIsSideMenuOpen={(isOpen) => {
            setIsDrawerOpen(false);
            setSelectedUID(prevUID);
            setIsSideMenuOpen(isOpen);
          }}
        />
        {isMobileDevice && selectedElements.length > 1 && isSideMenuOpen && (
          <MultiTieredSideMenuBackHeader
            onClick={() => {
              const _se = [...selectedElements];
              _se.pop();
              setSelectedElements(_se);
            }}
            title={selectedElements[selectedElements.length - 1].title}
          />
        )}
        {selectedElements &&
          selectedElements.length > 0 &&
          handleChildren(
            isSideMenuOpen
              ? isMobileDevice
                ? selectedElements[selectedElements.length - 1].childrens
                : selectedElements[0].childrens
              : [
                  selectedElements[0].childrens.filter(
                    (c) => c.icon === docCenterSidemenuIconHome
                  )[0],
                ], // When side menu is not open show only the home icon
            0,
            false
          )}
        {showFooter && (
          <Box
            className={`side-bar-menu-footer ${!isSideMenuOpen ? "collapsed" : ""}`}
          >
            {envName}
          </Box>
        )}
      </VStack>
    );
  };

  const getDraweredContent = (
    item: MultiTieredSideMenuDataModel,
    index: number
  ) => {
    const isLastColumn = selectedElements.length - 1 === index + 1;
    const shouldDisplayRightBorder =
      item.childrens.filter((item) => item.isSelected ?? false).length > 0 &&
      !isLastColumn;
    return (
      <GridItem
        className={`grid-item ${shouldDisplayRightBorder ? "enable-right-border" : ""}`}
        key={`gridItemMenu${index}`}
        animation="slideIn 0.5s linear"
        zIndex={10 - index}
      >
        <Grid className="grid-data">
          {handleChildren(item.childrens, index + 1, item.type === "accordion")}
        </Grid>
      </GridItem>
    );
  };

  const refDrawer = useRef<any>();
  const getDraweredMenu = () => {
    return (
      <>
        <Box className="drawer-box-container" ref={refDrawer}></Box>
        <Portal containerRef={refDrawer}>
          <Drawer
            placement="left"
            onClose={() => {
              setSelectedUID(prevUID);
              setIsDrawerOpen(false);
            }}
            finalFocusRef={refDrawer}
            isOpen={isDrawerOpen}
            closeOnOverlayClick={true}
            trapFocus={false}
          >
            <DrawerContent
              className="drawer-content"
              style={{
                maxWidth: `${drawerMaxWidth}px`,
                marginLeft: `${MAX_WIDTH}px`,
              }}
              animation="slideIn 0.5s ease-in-out"
            >
              <DrawerBody className="drawer-body drawer-outer-container-body">
                <Grid className="grid-content">
                  {selectedElements
                    .slice(1, undefined)
                    .map((item, index) => getDraweredContent(item, index))}
                </Grid>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Portal>
      </>
    );
  };

  return (
    <Box
      className={`main-container-side-menu ${isSideMenuOpen ? "side-menu-open" : "side-menu-closed"}`}
    >
      {selectedElements.length > 0 && firstColumn(envName ?? "")}
      {!isMobileDevice && getDraweredMenu()}
    </Box>
  );
};

export default MultiTieredSideMenu;
