"use client";
import { Flex, Text } from "@chakra-ui/react";
import "./Loader.scss";

interface LoaderProps {
  spinnerColor?: string;
  spinnerSize?: string;
  isOverlay: boolean;
  loaderText?: string;
}

function Loader(props: LoaderProps) {
  const {
    spinnerColor = "#a0c2d7",
    spinnerSize = "90px",
    isOverlay = false,
    loaderText = "Loading...",
  } = props;
  
  if (isOverlay) {
    return (
      <div className="overlay">
        <div
          className="main"
          style={{ color: spinnerColor, fontSize: spinnerSize }}
        />
      </div>
    );
  } else {
    return (
      <Flex alignItems={"center"}>
        <div
          className="loader"
          style={{
            borderColor: spinnerColor,
            width: spinnerSize,
            height: spinnerSize,
          }}
        />
        <Text ml={"10px"}>{loaderText}</Text>
      </Flex>
    );
  }
}

export default Loader;
