import { Box, Text } from "@chakra-ui/react";

import "./CommonTitle.scss";

type CommonTitleProps = {
  depositName: string;
  featureName?: string;
  version?: string | number;
  mainTitle: string;
  subText: string;
  versionTextStyleClass?: string;
  mainTitleStyleClass?: string;
  subTextStyleClass?: string;
};

const CommonTitle = (props: CommonTitleProps) => {
  const {
    depositName = "",
    featureName = "",
    version = "",
    mainTitle,
    subText,
    versionTextStyleClass,
    mainTitleStyleClass,
    subTextStyleClass,
  } = props;

  let versionTexts = `${depositName} ${
    depositName && (featureName || version) ? " |" : ""
  } ${featureName} ${featureName && version ? " |" : ""} ${version}`;

  return (
    <Box className="common-title">
      {versionTexts.trim() && (
        <Text className={`common-version-text ${versionTextStyleClass}`}>
          {versionTexts}
        </Text>
      )}
      <Text className={`common-main-title ${mainTitleStyleClass}`} as="h3">
        {mainTitle}
      </Text>
      <Text className={`common-sub-text ${subTextStyleClass}`}>{subText}</Text>
    </Box>
  );
};

export default CommonTitle;
