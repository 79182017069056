import { Flex, InfoIcon, Text } from "@/components/ChakraUiManager";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image from "next/image";
import style from "./FormFieldMsg.module.scss";

type FormFieldMsgProp = {
  image?: StaticImport | string;
  msg: string;
};

export default function FormFieldMsg(props: FormFieldMsgProp) {
  const { msg = "", image } = props;

  return (
    <Flex className={`${style["form-field-msg-container"]}`} alignItems={"center"}>
      {image ? <Image src={image} alt="msg icon" /> : <InfoIcon />}
      <Text className={`${style["form-field-msg"]}`}>{msg}</Text>
    </Flex>
  );
}
