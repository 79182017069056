import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { MultiTieredSideMenuDataModel } from "../MultiTieredSideMenuDataModel";
import variables from "../../../../../styles/_export.module.scss";
import "./MultiTieredSideMenuHeader.scss"

// Menu Header
interface MultiTieredSideMenuHeaderProps {
  keyName: string;
  data: MultiTieredSideMenuDataModel;
  isSideMenuOpen: boolean;
}

const MultiTieredSideMenuHeader = ({
  keyName,
  data,
  isSideMenuOpen
}: MultiTieredSideMenuHeaderProps) => {
  const { title } = data;

  return (
    <VStack key={keyName} className="main-content" hidden={!isSideMenuOpen}>
      {title && <HStack className="h-stack">
        <div className="title">
          {title.toUpperCase()}
        </div>
      </HStack>}
    </VStack>
  );
};

export default MultiTieredSideMenuHeader;
