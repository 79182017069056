"use client";
import React, { useEffect, useRef, useState } from "react";
import "./HoverTooltip.scss";
import { Box, Image, Tooltip } from "@/components/ChakraUiManager";

type TooltipProps = {
  placement: "top" | "right" | "bottom" | "left";
  IconDeactivated?: boolean;
  onToggle?: any;
  children?: React.ReactNode;
  label: any;
  width?: any;
  height?: any;
};

export default function ToolTip({
  placement = "right",
  IconDeactivated,
  onToggle,
  label,
  children,
}: TooltipProps) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event?.target)) {
      setTooltipVisible(false);
    }
  };

  const toggleTooltip = () => {
    if (!IconDeactivated) {
      setTooltipVisible(!isTooltipVisible);
      if (onToggle) onToggle(!isTooltipVisible);
    }
  };
  const hideToggleTooltip = () => {
    if (!IconDeactivated) {
      setTooltipVisible(!isTooltipVisible);
      if (onToggle) onToggle(!isTooltipVisible);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseover", handleClickOutside);
    return () => {
      document.removeEventListener("mouseout", handleClickOutside);
    };
  }, []);

  return (
    <Tooltip
      hasArrow
      arrowPadding={10}
      arrowSize={18}
      placement={placement}
      label={label}
      bg="#26292e"
      className="tooltip-hover-content"
    >
      <Box pt={3}>{children}</Box>
    </Tooltip>
  );
}
