import { Tooltip } from "@chakra-ui/react";
import "./GeneralizedTooltip.scss";
import { ReactNode } from "react";

type GenralizedTooltipProps = {
  children: ReactNode;
  content: string;
  hasArrow?: boolean;
  placement?: "top" | "right" | "bottom" | "left";
};

const GeneralizedTooltip = (props: GenralizedTooltipProps) => {
  const { children, content, hasArrow = true, placement = "right" } = props;
  return (
    <Tooltip
      label={content}
      hasArrow={hasArrow}
      placement={placement}
      className="genralized-tooltip-container"
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default GeneralizedTooltip;
