import React, { useEffect, useState } from "react";
import "./AcademyCourseData.scss";
import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Text,
} from "@chakra-ui/react";
import {
  getLearnerObjectsByIds,
  getLearnerObjectInstancesByIds,
} from "@/client-api-manager/alm-api";
import {
  LearningObjectDetails,
  LearningObjectContentItems,
} from "@/models/academy-models";
import {
  constructContentItems,
  constructLearningObject,
} from "@/components/academy/learning-util-service";
import EmbeddablePlayer from "@/components/academy/catalog/course-page/video-player/EmbeddablePlayer";
import { ArrowBackIcon } from "@chakra-ui/icons";

interface AcademyCourseDataProps {
  courseId: string | undefined;
  back: () => void;
}

const AcademyCourseData: React.FC<AcademyCourseDataProps> = ({
  courseId,
  back,
}) => {
  const [course, setCourse] = useState<LearningObjectDetails>();
  const [contentItems, setContentItems] = useState<
    LearningObjectContentItems[]
  >([]);
  const [playerKey, setPlayerKey] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (courseId) {
        getLearnerObjectsByIds(courseId).then(async (res) => {
          const constructedCourses = constructLearningObject(res.data, true);
          setCourse(
            constructedCourses.length > 0 ? constructedCourses[0] : undefined,
          );

          if (
            constructedCourses.length > 0 &&
            constructedCourses[0].instanceIds
          ) {
            // Check if instanceIds is an array and join, otherwise use it directly
            const instanceIds = Array.isArray(constructedCourses[0].instanceIds)
              ? constructedCourses[0].instanceIds.join(",")
              : constructedCourses[0].instanceIds;

            getLearnerObjectInstancesByIds(
              constructedCourses[0].learnerObjectId,
              instanceIds,
              "loResources",
            ).then((res) => {
              const constructedContentItems = constructContentItems(res.data);
              setContentItems(constructedContentItems);
            });
          }
        });
      }
    };
    fetchData();
  }, [courseId]);

  if (!course || contentItems.length === 0) return <div>Loading...</div>;

  return (
    <Box className="academy-course-data-container">
      <DrawerCloseButton size="lg" position="absolute" top="0" right="0" />
      <DrawerHeader padding={0}>
        <Text className="flyout-header">
          <Button aria-label="back" className="back-btn" onClick={back}>
            <ArrowBackIcon />
          </Button>
          {course.name}
        </Text>
        <Text className="flyout-subheader">
          {course.description || "No description available"}
        </Text>
      </DrawerHeader>
      <DrawerBody padding={0}>
        {/* Load the first module ID in the embedded player */}
        <Box className="player-box">
          <EmbeddablePlayer
            key={`${playerKey}-${contentItems[0].moduleId}`}
            type="course"
            courseId={course.learnerObjectId}
            moduleId={contentItems[0].moduleId}
          />
        </Box>
      </DrawerBody>
    </Box>
  );
};

export default AcademyCourseData;
