import "./AvatarIcon.scss";
import { Avatar, Box, Image } from "@chakra-ui/react";
import { manageAccountIcon } from "public/assets";
type AvatarIConProps = {
  type: string;
  size?: string;
};

function AvatarIcon({ type, size }: AvatarIConProps) {
  switch (type) {
    case "circle":
      return <Avatar size={size} className="avatar-class-circle"></Avatar>;
    case "square":
      return (
        <Box className="avatar-square-box">
          <Avatar size="lg" className="avatar-class" />
        </Box>
      );
    case "none":
      return (
        <>
          <Avatar size="lg" className="avatar-class" />
        </>
      );
    case "access":
      return (
        <>
          <Image src={manageAccountIcon.src} alt="Profile" />
        </>
      );
    default:
      return (
        <Avatar size="lg" name="John Doe" src="https://bit.ly/dan-abramov" />
      );
  }
}

export default AvatarIcon;
