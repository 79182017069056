"use client";

import { Icon, Box} from "@chakra-ui/react";

const ArrowForwardIcon = (props: any) => (
    <Box>
      <Icon viewBox="0 0 10 16" {...props}>
        <path
          fill="currentColor"
          d="M7.06414 8.0026L0.939138 1.8776C0.730805 1.66927 0.63011 1.42274 0.637055 1.13802C0.643999 0.853299 0.751638 0.606771 0.959971 0.398438C1.1683 0.190104 1.41483 0.0859375 1.69955 0.0859375C1.98428 0.0859375 2.2308 0.190104 2.43914 0.398438L8.83497 6.8151C9.00164 6.98177 9.12664 7.16927 9.20997 7.3776C9.29331 7.58594 9.33497 7.79427 9.33497 8.0026C9.33497 8.21094 9.29331 8.41927 9.20997 8.6276C9.12664 8.83594 9.00164 9.02344 8.83497 9.1901L2.4183 15.6068C2.20997 15.8151 1.96692 15.9158 1.68914 15.9089C1.41136 15.9019 1.1683 15.7943 0.959971 15.5859C0.751638 15.3776 0.647471 15.1311 0.647471 14.8464C0.647471 14.5616 0.751638 14.3151 0.959971 14.1068L7.06414 8.0026Z"
        />
      </Icon>
    </Box>
  );

export default ArrowForwardIcon;