import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@/components/ChakraUiManager";
import "./CollapseExpandText.scss";
interface CollapseExpandProps {
  title: string;
  children?: React.ReactNode;
  className?: string;
}
const CollapseExpandText = (props: CollapseExpandProps) => {
  return (
    <div className={`custom-accordian-coll-exap ${props.className}`}>
      <Accordion className="collapse-expand-accordion-container" allowToggle={true}>
        <AccordionItem>
          <AccordionButton _hover={{ bg: "white" }}>
            <Box as="span" textAlign="left">
              {props.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>{props.children}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CollapseExpandText;
