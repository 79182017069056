const STORAGE_KEY = {
  PRODUCT_GROUP_INFO: "productGroupInfo",
  PRODUCT_GROUP_LIST: "productGroupList",
  PRODUCT_ADDITIONAL_FEATURES: "additionalFeatures",
  PRODUCT_NAME: "productName",
  PRODUCT_DETAILS: "productDetails",
  PRODUCT_QUICK_START_FLAG: "isSummaryQucikStartFlow",
  IC_COPY_FLAG: "icCopy",
  GL_COPY_FLAG: "isGlCopy"
};

const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();
type StorageType = "session" | "local";
const storageType = (type?: StorageType): "localStorage" | "sessionStorage" => {
  return `${type ?? "session"}Storage`;
};
const stringifyData = (data: any) => (data ? JSON.stringify(data) : "");

const parseData = (data: any) => (data ? JSON.parse(data) : "");

function setItemInStorage(
  key: string,
  item: any,
  type: StorageType = "session"
) {
  if (isBrowser) {
    window[storageType(type)].setItem(key, stringifyData(item));
  }
}

function getItemFromStorage(key: string, type: StorageType = "session") {
  if (isBrowser) {
    const data = window[storageType(type)].getItem(key);
    return parseData(data);
  }
  return "";
}

function removeItemFromStorage(key: string, type: StorageType = "session") {
  if (isBrowser) {
    window[storageType(type)].removeItem(key);
  }
}

function clearStorage(type: StorageType = "session") {
  if (isBrowser) {
    window[storageType(type)].clear();
  }
}

export {
  setItemInStorage,
  getItemFromStorage,
  removeItemFromStorage,
  clearStorage,
  STORAGE_KEY,
};
