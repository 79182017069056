//Error_Message: Only For Frequency Module, Kindly use for all frequency module
const frequencyModuleError = {
  interval: "Please fill interval value,",
  cycle: "Please select cycle value",
  month: "Please select day",
  notBusinessDay: "Please select not business day",
};
// Error_Message For Min_Max Column, kindly use for all Min Max Value in application
const minMaxError = {
  min: "Please enter minimum value",
  max: "Please enter maximum value",
};
//Date_Time_Error
const dateTimeError = {
  date: "Please select date",
  time: "Please select time",
};

const nominalError = {
  min: "Should be >= 0",
  max: "Should be <= 100",
  greaterThanOne: "Should be >= 1",
};

const additionalAttributesZeroBalanceDaysError = {
  min: "Should be >= 0",
  max: "Should be <= 65535",
};

//Generic Error Message
const GENERIC_ERROR = {
  required: "Incomplete required field",
  frequencyPattern:
    "Please enter valid code like 1BA, 1M2A,1B, 1D, 1Y, 1M, 1ME, 1Y31",
  pattern: "Code does not match required pattern",
  url: "Input should be in URL format",
  durationError: "Please enter a valid code like 1B, 1D, 1Y, 1M",
  minMaxMsg: "Maximum value should be more than minimum balance",
  alphaNumeric: "Please enter a alphanumber values.",
  maxNumberError: "Please enter value less than 100",
  minNumberError: "Please enter value  greater than 0",
  numberTypeError: "Enter a valid number",
  toRestrictNoOfDigitsError:
    "Maximum number of digits allowed before decimal is 14",
  totalCountError: "Please enter value <= 65535",
  positiveNumberError: "Minimum range should be greater than 0",
  maxRangeError: "Maximum range should be greater than min value",
  rangeHundredError: "Please enter value <= 65535",
  maxNsfLimit: "Please enter less than 255",
  max20: "Please enter value <= 20",
  maxDigits: ({ max }: { max: number }) =>
    `Maximum number of digits allowed before decimal is ${max.toString().length}`,
  maxCharLength: ({ max }: { max: number }) =>
    `Maximum charaters allowed is: ${max}`,
  minValueRequired: "Atleast one value is required",
  max20Char: "Maximum number of characters allowed is 20",
};

export const ENV_CONFIG_MESSAGES = {
  no: "This environment is backed by configuration as code. You cannot edit the configuration directly",
  "with-warning":
    "This environment is backed by configuration as code.  You should not edit configuration directly under normal circumstances.  Any edits you make will not be captured / retained and may cause the need to wipe data the next time a build is loaded.",
};

export const MAX_INT_DIGITS = 14;

const ENTITLEMENT_MESSAGE = {
  noAccess:
    "Access Restricted: You do not have permission to edit. Please contact your system administrator to request access.",
  noLoanPdtAccess:
    "Access Restricted: You do not have permission to view/edit Loan products. Please contact your system administrator to request access.",
  noDepositPdtAccess:
    "Access Restricted: You do not have permission to view/edit Deposit products. Please contact your system administrator to request access.",
};

export const CORE_UNAVAILABLE_ERR_MSG =
  "Unable to connect to the Core. Please contact Finxact Support to help resolve your issue.";

export {
  frequencyModuleError,
  minMaxError,
  dateTimeError,
  GENERIC_ERROR,
  nominalError,
  additionalAttributesZeroBalanceDaysError,
  ENTITLEMENT_MESSAGE,
};
