import React, { useContext, useState } from "react";
import "./PercentageInput.scss"; // You can style this component with CSS as needed
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { IFX_ACCT_PRODUCT_TYPE, checkReadOnlyEntitlement, isConsolePages, useUserEntitlement } from "@/utils";
import { ProductConfigurationContextType, productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

type PercentageInputProps = {
  label: string;
  value: any;
  onChange: any;
};

function PercentageInput({
  label = "",
  value,
  onChange,
}: PercentageInputProps) {
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  // Function to handle changes in the input
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    // Handle empty input separately to allow clearing all digits
    if (inputValue === "") {
      onChange(0);
    } else if (
      /^\d+(\.\d{0,2})?$/.test(inputValue) &&
      inputValue >= 0 &&
      inputValue <= 100
    ) {
      onChange(parseFloat(inputValue) / 100);
    }
  };
  const formattedValue = Math.round(value * 100);

  return (
    <div className="percentage-input">
      <div className="input-heading">{label}</div>
      <div className="input-box">
        <input
          type="number"
          step="0.01"
          value={formattedValue}
          onChange={handleInputChange}
          min="0"
          max="100"
          pattern="\d+(\.\d{0,2})?" // Allow empty input and valid percentages
          inputMode="decimal" // Set input mode to "decimal" for better browser compatibility
          disabled={(envIsDisabled && isConsolePage) || checkEntitlement}
        />
        <span>%</span>
      </div>
    </div>
  );
}

export default PercentageInput;
