"use client";

import { TERM_DROPDOWN_OPTIONS } from "@/components/data/form-data";
import AppInputWithDropdown, {
  AppInputWithDropdownProps,
} from "../app-input-with-dropdown/AppInputWithDropdown";
import { useRef } from "react";
import { Box } from "@/components/ChakraUiManager";
import "./DurationInput.scss";

interface Props extends Omit<AppInputWithDropdownProps, "dropdownList"> {}

const DurationInput = (props: Props) => {
  const durationInputRef = useRef<any>();
  return (
    <>
      <Box className="duration-input-modal" ref={durationInputRef}></Box>
      <AppInputWithDropdown {...props} dropdownList={TERM_DROPDOWN_OPTIONS} containerRef={durationInputRef} />
    </>
  );
};

export default DurationInput;
