"use client";
import React, { useState, useCallback } from "react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { CodeContainer } from "@/components/common";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { python } from "@codemirror/lang-python";
import { java } from "@codemirror/lang-java";
import { Extension } from "@codemirror/state";
import { dracula, draculaInit } from "@uiw/codemirror-theme-dracula";
import "./CodeBlock.scss";
import { Box } from "@chakra-ui/react";
import { tags as t } from "@lezer/highlight";

/**
 * Props for `CodeBlock`.
 */
export type CodeBlockProps = SliceComponentProps<Content.CodeBlockSlice>;

type LanguageMap = {
  [key: string]: Extension;
};

export const CODE_BLOCK_SLICE_CLASSNAME = "code-block-slice";

const getLanguageExtension = (language: string) => {
  const languageMap: LanguageMap = {
    javascript: javascript(),
    json: json(),
    python: python(),
    java: java(),
  };
  return languageMap[language?.toLowerCase()] || json(); // Default to javascript if unknown
};
/**
 * Component for "CodeBlock" Slices.
 * props is any type because we are
 */
const CodeBlock = ({ slice }: any): JSX.Element => {
  const { code } = slice.data;
  let { language } = code;

  const [value, setValue] = useState(code);
  const onChange = useCallback((val: string) => {
    setValue(val);
  }, []);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={CODE_BLOCK_SLICE_CLASSNAME}
    >
      <Box className="code-block-wrap">
        <CodeMirror
          value={value}
          theme={draculaInit({
            styles: [
              { tag: t.bool, color: "#cbb2ff" },
              { tag: t.number, color: "#cbb2ff" },
              { tag: t.string, color: "#f1fa8c" },
            ],
          })}
          extensions={[getLanguageExtension(language)]}
          readOnly={true}
          basicSetup={{
            highlightActiveLineGutter: false,
            foldGutter: false,
            highlightActiveLine: false,
          }}
        />
        {/* <CodeContainer sticky={true} slice={slice} /> */}
      </Box>
    </section>
  );
};

export default CodeBlock;
