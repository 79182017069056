"use client";

import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type AppPortalProps = {
  children: ReactNode;
  domId?: string;
};

const AppPortal = ({ children, domId = "#appPortal" }: AppPortalProps) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const ref = useRef<Element | null>(null);
  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(domId);
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export default AppPortal;
